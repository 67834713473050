import React from "react";

export const LoaderContainer = (props) => {
  const { message = "Loading.....", loading, position = "fixed" } = props;
  if (!loading) {
    return "";
  }
  return (
    <div className={`loader-container is-${position}`}>
      <div className="loader-container__content">
        <div className="loader-container__icon">
          <BallPulseRise />
        </div>
        <div className="loader-container__text">{message}</div>
      </div>
    </div>
  );
};

const BallPulseRise = (props) => {
  return (
    <div className="loader">
      <div className="ball-pulse-rise">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
