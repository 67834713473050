import config from "config.json";
import http from "core/service/httpService";

export function getNodesBySiteId(siteId) {
  return http.get(`${config.apiEndpoint}/nodes/assign/${siteId}`);
}

export function getLiveReadings() {
  return http.get(`${config.apiEndpoint}/nodes/liveReadings`);
}

export function getLiveReadingsBySite(siteId) {
  return http.get(`${config.apiEndpoint}/nodes/liveReadings/${siteId}`);
}

export function getDefaultReadings(nodeId, from, to, interval) {
  return http.post(`${config.apiEndpoint}/nodes/readings`, {
    nodeId,
    from,
    to,
    interval,
  });
}

export function generateNodeReport(parameters) {
  return http.blobPost(
    `${config.apiEndpoint}/reports/node/download`,
    parameters
  );
}


export function saveExceedenceLimits(body) {
  return http.post(`${config.apiEndpoint}/limits`, body);
}

export function getAssignAlerts(nodeId) {
  return http.get(`${config.apiEndpoint}/limits/alerts/${nodeId}`);
}

export function getActiveAlertsByNodeId(nodeId) {
  return http.get(`${config.apiEndpoint}/exceedence/active/${nodeId}`);
}