import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const colors = [
  "#FF0000",
  "#0000FF",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
  "#ff0000",
];
const totalColors = _.size(colors);

const TimeChartContainer = ({
  readings,
  timeKey,

  dataKey,
  index = 0,
  brush = false,
  dataDisplayName = "",
}) => {
  const colorCode = colors[index % totalColors];
  const legendPayload = [{ value: dataDisplayName, type: "line", id: "ID01" }];
  const calculatedHeight = brush ? 240 : 200;
  return (
    <ResponsiveContainer width="100%" height={calculatedHeight}>
      <LineChart
        data={readings}
        margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
        syncId={"sync-chart"}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={timeKey}
          tick={{ fontSize: 10 }}
          tickFormatter={(time) => new Date(time).toLocaleTimeString()}
        />
        <YAxis domain={["dataMin", "dataMax"]} />
        <Tooltip
          labelFormatter={(value) =>
            moment(new Date(value)).format("YYYY/MM/DD HH:mm:ss")
          }
        />
        <Legend
          formatter={(value, entry, index) => {
            return legendPayload[index]["value"];
          }}
          verticalAlign="top"
        />
        <Line
          type="monotone"
          dataKey={dataKey}
          dot={false}
          stroke={colorCode}
          fill={colorCode}
        />
        {brush ? (
          <Brush
            dataKey={timeKey}
            tickFormatter={(time) => new Date(time).toLocaleTimeString()}
          />
        ) : (
          ""
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

const ParameterCyclicChart = (props) => {
  const { parameters, readings } = props;
  const totalParameters = _.size(parameters);
  const [formattedReadings, setFormattedReadings] = useState([]);
  useEffect(() => {
    if (_.size(readings) > 0) {
      const tempReadings = [];
      _.forEach(readings, function (reading) {
        const { epochTime, parameters } = reading;
        const temp = { timestamp: epochTime * 1000 };
        _.forEach(parameters, function (param) {
          const { identifier, processedValue } = param;
          temp[identifier] = _.round(processedValue, 3);
        });
        tempReadings.push(temp);
      });
      setFormattedReadings(tempReadings);
    }
  }, [readings]);
  return (
    <div>
      {parameters.map((param, index) => {
        const { name, identifier } = param;
        return (
          <TimeChartContainer
            readings={formattedReadings}
            timeKey={"timestamp"}
            dataKey={identifier}
            key={identifier}
            index={index}
            brush={index == totalParameters - 1}
            dataDisplayName={name}
          />
        );
      })}
    </div>
  );
};

export default ParameterCyclicChart;
