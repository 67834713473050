import config from "config.json";
import http from "core/service/httpService";

export function getAssignedSites() {
  return http.get(`${config.apiEndpoint}/sites/assigned/me`);
}

export function getAssignedNodes() {
  return http.get(`${config.apiEndpoint}/nodes/assigned/me`);
}

export function getLoggedInUserDetails() {
  return http.get(`${config.apiEndpoint}/users/me`);
}

export function getAssignedProcessTags() {
  return http.get(`${config.apiEndpoint}/processTags/assign/me`);
}

export function getLicensesByUserId() {
  return http.get(`${config.apiEndpoint}/licenses/search/user/`);
}
