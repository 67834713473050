import React, { Component } from "react";

export const UserDetailsCard = (props) => {
  const { details: inputDetails } = props;
  let details = inputDetails;
  if (details == undefined) {
    details = {
      name: "John Doe",
      contactNumber: "0000000000",
      email: "user@example.com",
    };
  }
  const { name, contactNumber, email } = details;
  return (
    <div className="card card-full-height min-height--1">
      <header className="card-header">
        <p className="card-header-title">User Details</p>
      </header>
      <div className="card-content">
        <p className="title is-6">{name}</p>
        <p className="subtitle is-6 ">{contactNumber}</p>
        <p className="subtitle is-6 ">{email}</p>
      </div>
      {/* /.card-content */}
    </div>
  );
};
