import React, { Component } from "react";

export const ParameterCardV2 = ({ parameter, currentReading }) => {
  const {
    name,
    limitMax,
    limitMin,
    rangeMax,
    rangeMin,
    unit,
    identifier,
  } = parameter;
  return (
    <div className="parameter-card-2">
      <div className="parameter-card-2__name">{name}</div>
      <div className="parameter-card-2__value">{`${currentReading} ${unit}`}</div>
      <div className="parameter-card-2__standard">
        {`Approved Limit : ${limitMin} - ${limitMax} ${unit}`}
      </div>
      <div className="parameter-card-2__range">{`Range : ${rangeMin} - ${rangeMax} ${unit}`}</div>
    </div>
  );
};
