import React, { Component, useEffect, useState } from "react";
import { getAssignedFeatures } from "./featureService";
import { toast } from "react-toastify";
import dummy from "./dummyData.json";
const FeatureStateContext = React.createContext();

function FeatureProvider({ children, initialFeatures = [] }) {
  const [features, setFeatures] = useState(initialFeatures);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: features } = await getAssignedFeatures();
        // console.log(features);
        setFeatures(features);
        // setFeatures(dummy);
      } catch (ex) {
        console.log(ex);
        toast.error("Error while fetching feature data");
      }
    };
    fetchData();
  }, []);

  return (
    <FeatureStateContext.Provider value={features}>
      {children}
    </FeatureStateContext.Provider>
  );
}

function useFeatureState() {
  const context = React.useContext(FeatureStateContext);
  if (context === undefined) {
    throw new Error("useFeatureState must be used within a FeatureProvider");
  }
  return context;
}

export { FeatureProvider, useFeatureState };
