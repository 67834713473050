import config from "config.json";
import http from "core/service/httpService";

export function getAssignedReportTemplates() {
  return http.get(`${config.apiEndpoint}/reports/template/assign/me`);
}

export function generateJasperReport(parameters) {
  return http.blobPost(
    `${config.apiEndpoint}/reports/advanced/download`,
    parameters
  );
}
