import React, { useState } from 'react';
import { BreadcrumbItem } from "core/components/breadcrumb";
import { BreadcrumbContainer } from "core/components/breadcrumb";
import { Marquee } from "core/components/marquee";
import { PageHeader } from "core/components/pageHeader";
import { Panel } from "core/components/tab";
import { TabContainer } from "core/components/tab";
import { useMasterListState } from "features/masterList/masterListProvider";
import { NodeContainerTagWise } from "features/node/nodeContainerTagWise";
import { DashboardSiteUserDetails } from "./dashboardSiteUserContainer";
import { CompanyLiveFeed } from "./liveFeed";
import { AlertTable } from "./alertTable";
import TourComponent from "core/components/takeTour";
import Tour from 'core/components/takeTour';
import { stepsDashboard } from 'core/components/stepDashboard';

export const Dashboard = () => {
  const [runTour, setRunTour] = useState(false);
  
  const masterList = useMasterListState();
  // console.log(masterList);
  const { sites, nodes } = masterList;
  // console.log(sites);
  if (sites.length == 0) {
    return "";
  }
  const site = sites[0];
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Dashboard"}
          description={"details about dashboard"}
        />
        <BreadcrumbContainer>
        <TourComponent/>
        <Tour
            steps={stepsDashboard}
            run={runTour}
            continuous={true}
            callback={(data) => {
              if (data.status === 'finished') {
                // Set the runTour state to false when the tour is finished
                setRunTour(false);
              }
            }}
          />
          <span className='tag tour_btn box-shadow is-small is-info' onClick={() => setRunTour(true)}>
          <span>Tour</span>
          <span class="icon is-small">
          <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-info"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"/><line x1="12" x2="12" y1="16" y2="12"/><line x1="12" x2="12.01" y1="8" y2="8"/></svg>    </span>
          
          </span>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Dashboard"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}

      <div className="content-container__body">
        <div className="dashboard">
       
 
    {/* <div class="has-text-right px-5">
      <p class="animate-charcter"> This app is in compliance with CPCB Guidelines</p>
    </div> */}


          <div className="dashboard__details">
            <DashboardSiteUserDetails site={site} />
          </div>
          <div className="box m-2">
            <TabContainer selected={0}>
              <Panel title="Details" >
                <NodeContainerTagWise nodes={nodes} />
              </Panel>
              <Panel title="Live Feed">
                <CompanyLiveFeed />
              </Panel>
              <Panel title="Alert">
                <AlertTable />
              </Panel>
            </TabContainer>
            <div class="has-text-centered pt-4">
      <p class="animate-charcter"> This app is in compliance with CPCB Guidelines</p>
    </div> 
          </div>
          
        </div>
      </div>
    </React.Fragment>
  );
};
