import React, { Component, useEffect, useState } from "react";
import { Table } from "core/components/table";
import { toast } from "react-toastify";
import { useLoading } from "core/hooks/loadingHook";
import { getAlertsForLoggedInCompany } from "./alertService";
import moment from "moment";
import _ from "lodash";

export const LatestAlertsTable = () => {
  const { loaderContainer, startLoading, stopLoading } = useLoading("absolute");
  // setPosition("absolute");
  const [alerts, setAlerts] = useState([]);
  const [isTableHidden, setTableHidden] = useState(true);

  useEffect(() => {
    startLoading();
    const fetchData = async () => {
      const fromUnix = moment().subtract(30, "days").startOf("day").unix();
      const toUnix = moment().endOf("day").unix();
      // console.log(fromUnix);
      try {
        const { data: alerts } = await getAlertsForLoggedInCompany(
          fromUnix,
          toUnix
        );
        // console.log(alerts);
        if (alerts.length > 0) {
          stopLoading();
          setTableHidden(false);
          setAlerts(_.reverse(alerts));
        } else {
          stopLoading();
          setTableHidden(true);
          toast.warn("No data found");
        }
      } catch (error) {
        stopLoading();
        toast.error("Alerts data could not be fetched");
      }
    };
    // stopLoading();
    fetchData();
  }, []);

  const tableStyle = { height: "57rem", width: "100%" };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  const columnDefs = [
    {
      headerName: "Alerts in last 30 days",
      children: [
        {
          headerName: "TAG",
          field: "deviceTag",
        },
        {
          headerName: "PARAMETER",
          field: "parameter",
          minWidth: 150,
        },
        {
          headerName: "VALUE",
          valueGetter: function (params) {
            let unit = params.data.unit ? params.data.unit : "";
            // console.log(unit);
            unit = unit != "Minutes" ? unit : "";
            return `${params.data.value} ${unit}`;
          },
          minWidth: 250,
        },
        {
          headerName: "REFERENCE REASON",
          field: "reason",
          minWidth: 450,
        },
        {
          headerName: "GENERATED AT",
          valueGetter: function (params) {
            // const unit = moment(params.data.creationDate).unix();
            const timestamp = moment(params.data.creationDate).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            return `${timestamp}`;
          },
          minWidth: 200,
        },
      ],
    },
  ];

  return (
    <div className="table-container is-relative">
      {loaderContainer}
      <Table
        tableStyle={tableStyle}
        rowData={alerts}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        paginationAutoPageSize={true}
        pagination={true}
        paginationPageSize={10}
        height={"70rem"}
      />
    </div>
  );
};
