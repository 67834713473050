import React from "react";
import { useFeatureState } from "./featureProvider";
import _ from "lodash";

export const useFeatureHook = () => {
  const features = useFeatureState();

  const isAccessGranted = (requiredFeature) => {
    const result = _.find(features, ["name", requiredFeature]);
    return result != undefined;
  };

  return [isAccessGranted];
};
