import React, { Component } from "react";
import { NavLink } from "core/components/navLink";

export const CpcbSidebar = () => {
  return (
    <aside className="menu">
      <p className="menu-label">General</p>
      <ul className="menu-list">
        <NavLink link="/cpcb/dashboard">Dashboard</NavLink>
        <NavLink link="/cpcb/sites">Site</NavLink>
        <NavLink link="/cpcb/reports">Reports</NavLink>
        <NavLink link="/cpcb/profile">Profile</NavLink>
      </ul>
    </aside>
  );
};
