import React from "react";
import { UserDetailsCard } from "features/site/siteUserCard";
import { SiteDetails } from "features/site/siteDetailCard";

import { SiteMap } from "features/site/siteMap";

export const DashboardSiteUserDetails = ({ site }) => {
  let centerPosition = [0, 0];
  let siteList = [];
  let siteUser = undefined;
  if (site) {
    centerPosition = [site.latitude || 0, site.longitude || 0];
    siteList.push(site);
    const { contactPerson, contactNumber, contactEmail } = site;
    siteUser = { name: contactPerson, contactNumber, email: contactEmail };
  }
  // console.log(centerPosition);

  return (
    <div className="columns">
      <div className="column is-one-third">
        <SiteDetails site={site} />
      </div>
      <div className="column is-one-third">
        <UserDetailsCard details={siteUser} />
      </div>
      <div className="column is-one-third">
        <SiteMap
          zoom={14}
          siteList={siteList}
          centerPosition={centerPosition}
        />
      </div>
    </div>
  );
};
