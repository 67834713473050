import { Carousel, CarouselSlide } from "core/components/carousel";
import { ParameterCardV2 } from "./parameterCardV2";
import _ from "lodash";
import { CarouselV2, CarouselV2Slide } from "core/components/carouselv2";

const ParameterCarouselSlide = ({ parameter, currentReading = "NA" }) => {
  return (
    <CarouselV2Slide>
      <ParameterCardV2 parameter={parameter} currentReading={currentReading} />
    </CarouselV2Slide>
  );
};

export const ParametersCarouselCard = ({ parameters, readingMap }) => {
  if (parameters == undefined || parameters.length == 0) {
    return "";
  }
  // console.log(readingMap);
  const carouselSlides = [];
  const counter = 1;
  _.forEach(parameters, (parameter) => {
    const { identifier } = parameter;
    const currentReading = _.round(readingMap[identifier], 3);
    // console.log(currentReading);
    carouselSlides.push(
      <ParameterCarouselSlide
        key={identifier}
        parameter={parameter}
        currentReading={currentReading}
      />
    );
  });

  let carousels = "";
  // console.log(carouselSlides);
  if (carouselSlides.length > 1) {
    return <CarouselV2 slides={carouselSlides} timerInterval={2000} />;
  } else {
    return carouselSlides[0];
  }
  return carousels;
};
