import React, { useEffect, useState } from "react";

import _ from "lodash";
import {
  getLoggedInUserDetails,
  getAssignedProcessTags,
} from "./masterListService";
import { getAssignedNodes } from "./masterListService";
import { toast } from "react-toastify";
import dummy from "./dummyData.json";
import { getAssignedSites } from "./masterListService";
import { getLicensesByUserId } from "./masterListService";
const MasterlistStateContext = React.createContext();

function MasterlistProvider({ children }) {
  // const [masterList, setMasterList] = useState({
  //   sites: [],
  //   user: {},
  //   company: {},
  // });
  const [masterList, setMasterList] = useState({
    nodes: [],
    nodeTypes: [],
    nodeTags: [],
    processTags: [],
    user: {},
    company: {},
    sites: [],
    licenses:[],
  });
  useEffect(() => {
    const fetchData = async () => {
      const newMasterList = { ...masterList };
      // console.log(sites);

      //   const { data: company } = await getLoggedInCompanyDetails();
      //   newMasterList["company"] = company;
      try {
        const { data: user } = await getLoggedInUserDetails();
        newMasterList["user"] = user;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch user data");
      }

      try {
        const { data: sites } = await getAssignedSites();
        // console.log(sites);
        // const sites = dummy["sites"];
        newMasterList["sites"] = sites;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch sites");
      }

      try {
        const { data: licenses } = await getLicensesByUserId();
        // console.log(sites);
        // const sites = dummy["sites"];
        newMasterList["licenses"] = licenses;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch sites");
      }
     
      try {
        const { data: processTags } = await getAssignedProcessTags();
        // console.log(processTags);
        // const processTags = dummy["processTags"];
        newMasterList["processTags"] = processTags;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch process tags");
      }

      try {
        const { data: nodes } = await getAssignedNodes();
        // const nodes = dummy["nodes"];

        newMasterList["nodes"] = nodes;
        // console.log(nodes);

        const nodeTypes = _.uniq(_.map(nodes, "nodeType"));
        newMasterList["nodeTypes"] = nodeTypes;

        const nodeTags = _.uniq(_.map(nodes, "tagName"));
        newMasterList["nodeTags"] = nodeTags;
      } catch (error) {
        console.log(error);
        // toast.error("Unable to fetch node data");
      }
      setMasterList(newMasterList);
    };

    if (masterList["nodes"].length == 0) {
      fetchData();
    }
  }, []);
  return (
    <MasterlistStateContext.Provider value={masterList}>
      {children}
    </MasterlistStateContext.Provider>
  );
}

function useMasterListState() {
  const context = React.useContext(MasterlistStateContext);
  if (context === undefined) {
    throw new Error(
      "useMasterListState must be used within a MasterListProvider"
    );
  }
  return context;
}

export { MasterlistProvider, useMasterListState };
