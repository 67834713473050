import React, { Component } from "react";

export const PageHeader = (props) => {
  const { header, description } = props;
  return (
    <div className="page-header">
      <h3 className="page-header__main">{header}</h3>
      <span className="page-header__description">{description}</span>
    </div>
  );
};
