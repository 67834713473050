import React, { Component, useState } from "react";
import { toast } from "react-toastify";
import { PageHeader } from "core/components/pageHeader";
import {
  BreadcrumbItem,
  BreadcrumbContainer,
} from "core/components/breadcrumb";
import categoryList from "./companyCategory.json";
import _ from "lodash";
import { Table } from "core/components/table";
import { NavLink } from "core/components/navLink";
import { Link } from "react-router-dom";
import { useMasterListState } from "features/masterList/masterListProvider";

export const CpcbSiteList = () => {
  const { sites } = useMasterListState();
  const [selectedCategory, setSelectedCategory] = useState("ALL");
  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
  };

  // console.log(sites);

  let selectedCategorySites = [];

  if (selectedCategory === "ALL") {
    selectedCategorySites = sites;
  } else {
    selectedCategorySites = _.filter(sites, function (site) {
      return site.category === selectedCategory;
    });
  }
  // console.log(selectedCategorySites);

  const tableStyle = { height: "62rem", width: "100%" };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    minWidth: 150,
  };

  const DetailsButtonRenderer = ({ value }) => {
    // return <NavLink link={`/cpcb/site/${value}`}>View Details</NavLink>;
    // return "Hello";
    return (
      <Link to={`/cpcb/site/${value}`}>
        <button className="button is-small is-primary">Site Details</button>
      </Link>
    );
  };
  const columnDefs = [
    {
      headerName: "Category wise site",
      children: [
        {
          headerName: "Site",
          field: "name",
          minWidth: 150,
        },
        {
          headerName: "Company",
          field: "companyName",
        },
        {
          headerName: "Address",
          field: "address",
          minWidth: 150,
        },
        {
          headerName: "Category",
          field: "category",
          minWidth: 150,
          filter: false,
        },
        {
          headerName: "Status",
          field: "connectionStatus",
          minWidth: 150,
        },
        {
          headerName: "Details",
          minWidth: 150,
          valueGetter: function (params) {
            return params.data.id;
          },
          cellRenderer: DetailsButtonRenderer,
          cellRendererParams: {},
        },
      ],
    },
  ];

  // const DetailsButtonRenderer = ({ onClick, value, data }) => {

  const frameworkComponents = {
    btnCellRenderer: DetailsButtonRenderer,
  };

  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"All Sites"}
          description={"shows all cpcb connected sites"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"All Sites"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="box mx-2">
          <div className="container">
            <h6 className="title is-6">Site List</h6>
            <div className="field">
              <div className="control">
                <span className="is-6 mr-6">Select Category</span>
                <div className="select">
                  <select
                    name={"templateName"}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value={"ALL"}>All</option>
                    {categoryList.map((category, key) => (
                      <option key={key} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </div>
                {/* /.select */}
              </div>
              {/* /.control */}
            </div>
            {/* /.field */}
          </div>
          <div className="container">
            <Table
              tableStyle={tableStyle}
              rowData={selectedCategorySites}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              paginationAutoPageSize={true}
              pagination={true}
              paginationPageSize={10}
              frameworkComponents={frameworkComponents}
            />
          </div>
        </div>
        {/* /.box */}
      </div>
    </React.Fragment>
  );
};
