import React from "react";
import {
  BreadcrumbContainer,
  BreadcrumbItem,
} from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { FactoryIcon } from "core/components/svgIcon";
import { useLoading } from "core/hooks/loadingHook";
import { useMasterListState } from "features/masterList/masterListProvider";
import { SiteMap } from "features/site/siteMap";
import { StatsCard, StatsCardFooterItem } from "features/site/siteStatsCard";
import _ from "lodash";

export const CpcbDashboard = () => {
  const { loaderContainer, startLoading, stopLoading } = useLoading();

  const { sites } = useMasterListState();
  const onlineSites = _.filter(sites, function (site) {
    return _.toUpper(site.connectionStatus) === "ONLINE";
  });
  const offlineSites = _.filter(sites, function (site) {
    return _.toUpper(site.connectionStatus) === "OFFLINE";
  });
  const delayedSites = _.filter(sites, function (site) {
    return _.toUpper(site.connectionStatus) === "DELAY";
  });

  const centerPosition = [20.5937, 78.9629];
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Dashboard"}
          description={"details about dashboard"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Dashboard"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="columns">
          <div className="column is-one-half-desktop is-one-full-tablet">
            <div className="columns is-multiline">
              <div className="column is-half-tablet  is-one-half-desktop">
                <StatsCard
                  headerClassName={"is-primary"}
                  icon={
                    <div className="svgicon is-current-color">
                      <FactoryIcon />
                    </div>
                  }
                  title="Total Sites"
                  value={sites.length}
                  cardLink="/cpcb/sites"
                  footerItem={
                    <StatsCardFooterItem
                      itemTitle="View All"
                      link="/cpcb/sites"
                    />
                  }
                />
              </div>
              <div className="column is-half-tablet  is-one-half-desktop">
                <StatsCard
                  headerClassName={"is-primary"}
                  icon={
                    <div className="svgicon is-current-color">
                      <FactoryIcon />
                    </div>
                  }
                  title="Live Sites"
                  value={onlineSites.length}
                  cardLink="/cpcb/sites"
                  footerItem={
                    <StatsCardFooterItem
                      itemTitle="View All"
                      link="/cpcb/sites"
                    />
                  }
                />
              </div>

              <div className="column is-half-tablet  is-one-half-desktop">
                <StatsCard
                  headerClassName={"is-primary"}
                  icon={
                    <div className="svgicon is-current-color">
                      <FactoryIcon />
                    </div>
                  }
                  title="Delayed Sites"
                  value={delayedSites.length}
                  cardLink="/cpcb/sites"
                  footerItem={
                    <StatsCardFooterItem
                      itemTitle="View All"
                      link="/cpcb/sites"
                    />
                  }
                />
              </div>

              <div className="column is-half-tablet  is-one-half-desktop">
                <StatsCard
                  headerClassName={"is-primary"}
                  icon={
                    <div className="svgicon is-current-color">
                      <FactoryIcon />
                    </div>
                  }
                  title="Offline Sites"
                  value={offlineSites.length}
                  cardLink="/cpcb/sites"
                  footerItem={
                    <StatsCardFooterItem
                      itemTitle="View All"
                      link="/cpcb/sites"
                    />
                  }
                />
              </div>
            </div>
            {/* /.columns */}
          </div>

          <div className="column is-one-half-desktop is-one-full-tablet px-5 py-5">
            <SiteMap
              zoom={4}
              siteList={sites}
              centerPosition={centerPosition}
            />
          </div>
        </div>

        {loaderContainer}
      </div>
    </React.Fragment>
  );
};
