import React, { Component } from "react";
import {  MenuIcon2 } from "core/components/svgIcon";
import { LogoIcon2 } from "core/components/svgIcon__logo";
import { useNavigate} from 'react-router-dom';

export const CompanyHeader = (props) => {
  const { toggleSidebar, onLogout } = props;
  const navigate = useNavigate();
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/company/dashboard');
  };
  return (
    <React.Fragment>
      <div className="header__logo logo" onClick={navigateHome}>
        <div className="logo__icon">
          <LogoIcon2 />
          {/* <LogoIcon></LogoIcon> */}
        </div>
        <h4 className="logo__text has-text-weight-bold">Reconindia</h4>
      </div>
      {/* /.header__logo */}
      <div className="header__burger" onClick={toggleSidebar}>
        {/* <MenuIcon /> */}
        <MenuIcon2 />
      </div>

      <div className="header__tray">
      <button class="button is-primary  box-shadow" onClick={onLogout}>
<span> Logout </span>
<span class="icon is-small">
<svg  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"/><polyline points="16 17 21 12 16 7"/><line x1="21" x2="9" y1="12" y2="12"/></svg>
</span></button>
        {/* <button className="button is-primary" onClick={onLogout}>
          Logout
        </button> */}
      </div>
    </React.Fragment>
  );
};
