import React from "react";
import { SiteDetails } from "features/site/siteDetailCard";
import { UserDetailsCard } from "features/site/siteUserCard";

import { CarouselV2, CarouselV2Slide } from "core/components/carouselv2";
import { SiteLicenseCarouselCard } from "features/site/siteLicenseCarouselCard";
import { SiteMap } from "features/site/siteMap";
import { SiteDetails2 } from "features/site/siteDetailsCard2";
import { UserDetailsCard2 } from "features/site/siteUserCard2";

export const DashboardSiteUserDetails = ({ site }) => {
  let centerPosition = [0, 0];
  let siteList = [];
  if (site) {
    centerPosition = [site.latitude || 0, site.longitude || 0];
    siteList.push(site);
  }
  const { contactPerson, contactNumber, contactEmail } = site;
  const siteUser = { name: contactPerson, contactNumber, email: contactEmail };

  const userSlides = [];
  userSlides.push(
    <CarouselV2Slide>
      <UserDetailsCard2 details={siteUser} />
    </CarouselV2Slide>
  );
  userSlides.push(
    <CarouselV2Slide>
      <SiteDetails2 site={site} />
      {/* <SiteDetails site={site} /> */}
    </CarouselV2Slide>
  );
  return (
    <div className="columns is-multiline">
      <div className="column is-one-third">
        <SiteLicenseCarouselCard />
      </div>
      <div className="column is-one-third">
        <SiteMap
          zoom={14}
          siteList={siteList}
          centerPosition={centerPosition}
        />
      </div>
      <div className="column is-one-third">
        <CarouselV2 slides={userSlides} />
      </div>
    </div>
  );
};
