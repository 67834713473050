import React, { Component, useState } from "react";
import { Modal } from "core/components/modal";
import Joi from "joi";
import {
  EditTextField,
  SelectContainer,
  DateTimeField,
} from "core/components/inputWithAccessControl";

import { toast } from "react-toastify";
import { useSubmitForm } from "core/hooks/submitFormHook";
import ParameterCyclicChart from "./parameterCyclicChart";
import { useLoading } from "core/hooks/loadingHook";
import { getDefaultReadings } from "./nodeService";
import moment from "moment";
import { CrossIcon } from "core/components/svgIcon";
import _ from "lodash";
import { fetchAssertOptions } from "core/utility/util";
import { generateNodeReport } from "./nodeService";
import { downloadReport } from "core/utility/util";

export const ReportChartModal = ({ node, open, onClose }) => {
  // console.log(node);
  const { loaderContainer, startLoading, stopLoading } = useLoading();

  const schema = Joi.object({
    nodeId: Joi.number().required(),
    tag: Joi.string().required(),
    nodeType: Joi.string().required(),
    from: Joi.date().label("From").required(),
    to: Joi.date().label("To").required(),
    interval: Joi.number().label("Interval").required(),
  });

  const submitRequest = async () => {
    console.log();
  };
  const defaultInputs = {
    tag: node.tagName,
    nodeType: node.nodeType,
    interval: 15,
    nodeId: node.id,
  };
  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    validateForm,
    additionalValidation,
  } = useSubmitForm(schema, submitRequest, defaultInputs);

  const [hideCharts, setHideCharts] = useState(true);
  const [readings, setReadings] = useState([]);

  const handleChartClick = async (event) => {
    if (!validateForm()) {
      return;
    }
    setHideCharts(true);
    startLoading();
    const { tag, nodeType, from, to, interval, nodeId } = inputs;

    const fromUnix = moment(from, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").unix();
    const toUnix = moment(to, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").unix();
    const { data: values } = await getDefaultReadings(
      nodeId,
      fromUnix,
      toUnix,
      interval
    );
    if (values.length > 0) {
      stopLoading();
      // console.log(values);
      setReadings(values);
      setHideCharts(false);
    } else {
      stopLoading();
      toast.warn("No data found");
    }
  };

  const handleReportClick = async (event) => {
    if (!validateForm()) {
      return;
    }
    startLoading();
    const intervalDict = {
      1: "1D",
      5: "2D",
      15: "5D",
      60: "10D",
    };
    const { tag, nodeType, from, to, interval, nodeId } = inputs;
    const assertOptions = fetchAssertOptions(intervalDict[interval]);
    const additionalSchema = Joi.object({
      from: Joi.date().label("From Date").required(),
      to: Joi.date().label("To Date").required(),
    }).assert(assertOptions.condition, true, assertOptions.errorMessage);
    const inputsArray = ["from", "to"];

    const isValid = additionalValidation("to", additionalSchema, inputsArray);
    // console.log(values);
    if (!isValid) {
      toast.error("Validation Error");
      stopLoading();
      return;
    }
    let templateName = "default_node_1min";
    if (interval < 15) {
      templateName = "default_node_1min";
    } else if (interval >= 15) {
      templateName = "default_node_15min";
    }
    let fromDate = new Date();
    let toDate = new Date();
    if (_.has(inputs, "from")) {
      fromDate = from;
      toDate = to;
    }
    const input = {};
    input["node"] = [nodeId];
    input["node_tag"] = [tag];
    input["display_node_tag"] = tag;
    input["node_type"] = [nodeType];
    input["interval"] = _.toString(interval);
    input["display_node_type"] = nodeType;

    const values = {
      templateName: templateName,
      reportName: "Node Wise Report",
      from: fromDate,
      to: toDate,
      exportFormat: "pdf",
      input: input,
    };
    console.log(values);
    try {
      const response = await generateNodeReport(values);
      let fileName = response.headers["x-blob-file"];
      downloadReport(fileName, response);
      stopLoading();
    } catch (error) {
      stopLoading();
      toast.warn("Could not generate report");
    }
  };

  const intervalOptions = [
    { label: "1 min", value: 1 },
    { label: "5 min", value: 5 },
    { label: "15 min", value: 15 },
    { label: "1 hour", value: 60 },
  ];

  const accessToEdit = true;

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div className="">
        <div className="card report-chart-card report-chart-modal">
          <header className="card-header">
            <div className="card-header-title">
              <span className="is-size-4 has-text-centered">
                Reports &amp; Charts
              </span>
            </div>

            <span
              className="svgicon is-24x24 is-padding-small"
              style={{ cursor: "pointer" }}
              onClick={onClose}
            >
              <CrossIcon />
            </span>
          </header>
          <div className="card-content">
            <form onSubmit={handleSubmit}>
              <div className="columns is-multiline">
                <div className="column is-one-third-tablet is-one-quarter-desktop">
                  <DateTimeField
                    identifier="from"
                    labelName="From"
                    step={5}
                    editAccess={accessToEdit}
                    handleInputChange={handleInputChange}
                    inputs={inputs}
                    errors={errors}
                  />
                </div>
                <div className="column is-one-third-tablet is-one-quarter-desktop">
                  <DateTimeField
                    identifier="to"
                    labelName="To"
                    step={5}
                    editAccess={accessToEdit}
                    handleInputChange={handleInputChange}
                    inputs={inputs}
                    errors={errors}
                  />
                </div>
                <div className="column is-one-third-tablet is-one-quarter-desktop">
                  <SelectContainer
                    identifier="interval"
                    labelName="Time Interval"
                    editAccess={accessToEdit}
                    handleInputChange={handleInputChange}
                    inputs={inputs}
                    errors={errors}
                    options={intervalOptions}
                    defaultValue={15}
                  ></SelectContainer>
                </div>
              </div>
            </form>
          </div>
          {/* ./card-content */}
          <footer className="card-footer report-chart-card__footer">
            <div className="buttons">
              <button
                className="button is-primary is-right"
                onClick={handleChartClick}
              >
                Plot Chart
              </button>
              <button
                className="button is-primary is-right"
                onClick={handleReportClick}
              >
                Generate Report
              </button>
            </div>
          </footer>
        </div>
        {/* /.card ./report-chart-card */}

        {!hideCharts && (
          <div className="report-chart-container">
            <div className="columns is-centered">
              <div className="column is-11">
                <ParameterCyclicChart
                  parameters={node.parameters}
                  readings={readings}
                />
              </div>
            </div>
          </div>
        )}
        <div id="reportDiv" style={{ display: "none" }}></div>
        {loaderContainer}
      </div>
    </Modal>
  );
};
