import useWindowDimensions from "core/hooks/windowDimensionsHook";
import { logout } from "features/authentication/authService";
import { FeatureProvider } from "features/feature/featureProvider";
import { AuthenticationValidator } from "features/authentication/authenticationValidator";
import { MasterlistProvider } from "features/masterList/masterListProvider";
import React, { useEffect, useState } from "react";
import { Navigate, Redirect, Route, Routes, Switch } from "react-router-dom";
import { CpcbHeader } from "./cpcbHeader";
import { CpcbSidebar } from "./cpcbSidebar";
import { CpcbReport } from "./cpcbReport";
import { UserProfile } from "./profile";
import roles from "roles.json";
import { CpcbSiteList } from "./cpcbSiteList";
import { CpcbDashboard } from "./dashboard";
import { CpcbSite } from "./cpcbSite";

export const Cpcb = (props) => {
  const handleLogout = () => {
    logout();
    props.history.push("/");
  };

  const { width, height } = useWindowDimensions();
  const [sidebar, setSidebar] = useState(width > 1024);
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };
  let sidebarClass = "";
  if (sidebar) {
    sidebarClass = "sidebar";
  } else {
    sidebarClass = "sidebar-hidden";
  }

  const options = { requiredRole: roles.CPCB_ROLE };
  return (
    <AuthenticationValidator options={options}>
      <FeatureProvider>
        <MasterlistProvider>
          <React.Fragment>
            <div className="main">
              <header className="header">
                <CpcbHeader
                  toggleSidebar={toggleSidebar}
                  onLogout={handleLogout}
                />
              </header>
              <div className="content-area">
                <div className={`${sidebarClass}`}>
                  <CpcbSidebar />
                </div>
                <div className="content-container">
                  <Routes>
                    <Route path="sites" element={<CpcbSiteList />} />
                    <Route path="site/:id" element={<CpcbSite />} />

                    <Route path="dashboard" element={<CpcbDashboard />} />
                    <Route path="profile" element={<UserProfile />} />
                    <Route path="reports" element={<CpcbReport />} />
                    <Route path="/" element={<Navigate to="dashboard" />} />
                  </Routes>
                </div>
              </div>
            </div>
          </React.Fragment>
        </MasterlistProvider>
      </FeatureProvider>
    </AuthenticationValidator>
  );
};
