// Tour.js

import React from 'react';
import Joyride from 'react-joyride';

const Tour = ({ steps, run, continuous, ...props }) => {
  return (
    <Joyride
      steps={steps}
      run={run}
      continuous={continuous}
      {...props}
    />
  );
};

export default Tour;
