// https://profinit.eu/en/blog/build-once-deploy-many-in-react-dynamic-configuration-properties/#full-codebase
// Reference

export const defaultConfig = {
  apiUrl: "https://localhost:8080/undefinedApiUrl",
  environment: "DEV",
};

class GlobalConfig {
  config = defaultConfig;
  notDefinedYet = true;

  get() {
    if (this.notDefinedYet) {
      throw new Error("Global config has not been defined yet.");
    } else {
      return this.config;
    }
  }

  set(value) {
    if (this.notDefinedYet) {
      this.config = value;
      this.notDefinedYet = false;
    } else {
      throw new Error("Global config has already been defined");
    }
  }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "/config.json";
