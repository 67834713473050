import React from "react";
import logo from "./logo.svg";
import { ToastContainer } from "react-toastify";

import "./App.scss";
import { Login } from "views/login";
import { Company } from "views/company/company";
import { Cpcb } from "./views/cpcb/cpcb";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

function App() {
  return (
    <React.Fragment>
      <ToastContainer
        autoClose={4000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnFocusLoss={false}
      />
      <Router>
        <Routes>
          <Route path="/cpcb/*" element={<Cpcb />} />
          <Route path="/company/*" element={<Company />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
