import { TableWithGrid } from "core/components/tableWithGrid";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLoading } from "../../core/hooks/loadingHook";
import { getActiveAlertsByNodeId } from "./nodeService";

export const ExceedenecAlertTable = ({ onEdit, nodeId }) => {
    const { loaderContainer, startLoading, stopLoading } = useLoading("absolute");
    const [data, setData] = useState([]);


    const handleEditAction = (value) => {
        onEdit(value);
    };

    const ActionButtonRenderer = ({ value }) => {
        return (
            <React.Fragment>
                <button
                    className="button is-small is-primary has-text-weight-medium my-1 mt-2"
                    onClick={() => handleEditAction(value)}
                >
                    {/* <EditIcon /> */}
                    Details
                </button>
            </React.Fragment>
        );
    };

    const columnDefs = [
        // {
        //     headerName: "Node Id",
        //     field: "nodeId",
        // },
        {
            headerName: "Parameter",
            field: "parameterName",
            minWidth: 150,
        },
        {
            headerName: "Alert Limit",
            field: "alertLimit",
            minWidth: 150,
        },
        {
            headerName: "Alert Value",
            field: "alertValue",
            minWidth: 250,
        },
        {
            headerName: "Limit Type",
            field: "limitType",
            minWidth: 250,
        },
        {
            headerName: "Generated On",
            valueGetter: (params) => {
                const epochTime = params.data.generatedOn;
                const convertedTime = new Date(epochTime * 1000).toLocaleString();
                return convertedTime;
            },
            minWidth: 200,
        },

    ];

    useEffect(() => {
        const fetchData = async () => {
            startLoading();
            try {
                const { data: alerts } = await getActiveAlertsByNodeId(nodeId);
                const data = alerts;
                setData(data);
            } catch (error) {
                console.error("Error fetching alert data:", error);
            }
            stopLoading();
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            <div className="card mx-2 my-2 ">
                <TableWithGrid
                    rowData={data}
                    columnDefs={columnDefs}
                    pagination={true}
                />
            </div>

            {loaderContainer}
        </React.Fragment>
    );
};
