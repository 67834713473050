import React, { Component } from "react";
import moment from "moment";

export const SiteLicenseCard2 = ({ tagName, expiryDate }) => {
  const today = moment();
  const withinMonth = moment().add(30, "days");
  const withinTenDays = moment().add(10, "days");
  const expiry = moment(expiryDate);
  //  console.log(expiry);
  const buttonSection = expiry.isBefore(today) ? (
    <span className="tag is-warning  ml-4">EXPIRED</span>
  ) : (
    ""
  );

  return (
    <div
      className={`user_details_card2 is-light-green${
        expiry.isBetween(today, withinTenDays)
          ? "user_details_card2 is-maroon"
          : expiry.isBetween(today, withinMonth)
          ? "user_details_card2 is-yellow-light"
          : expiry.isBefore(today)
          ? "user_details_card2 is-maroon"
          : ""
      }`}
    >
      <div className="user__details_section">
        <div className="card__text__section">
          <h3
            style={{ display: "flex" }}
            className="has-text-weight-bold	has-text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-keyboard-music"
            >
              <rect width="20" height="16" x="2" y="4" rx="2" />
              <path d="M6 8h4" />
              <path d="M14 8h.01" />
              <path d="M18 8h.01" />
              <path d="M2 12h20" />
              <path d="M6 12v4" />
              <path d="M10 12v4" />
              <path d="M14 12v4" />
              <path d="M18 12v4" />
            </svg>
            License Details
            {buttonSection}
          </h3>
          <h2 className=" is-size-6 has-text-weight-bold has-text-white">
            {tagName}
          </h2>
          <h2 className=" is-size-6 has-text-white">
            EXPIRES ON:- {expiryDate}
          </h2>
        </div>
      </div>
    </div>
  );
};
