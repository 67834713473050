import config from "config.json";
import httpService from "core/service/httpService";
import jwt_decode from "jwt-decode";
import _ from "lodash";

export function login(username, password) {
  return httpService.openPost(config.apiEndpoint + "/users/login", {
    username,
    password,
  });
}

export function updateAccessToken() {
  const promise = httpService.openPost(
    config.apiEndpoint + "/users/token/refreshToken",
    {
      refreshToken: getRefreshToken(),
    }
  );
  return promise;
}

export function logout() {
  clearToken();
}

export function saveToken(token) {
  // console.log(token);
  localStorage.setItem("token", token);
}

export function saveRefreshToken(refreshToken) {
  // console.log(refreshToken);
  localStorage.setItem("refreshToken", refreshToken);
}

export function saveUser(user) {
  const obj = JSON.stringify(user);
  localStorage.setItem("user", obj);
}

export function clearToken() {
  localStorage.clear();
}

export function getToken() {
  // console.log(localStorage.getItem("token"));
  return `Bearer ${localStorage.getItem("token")}`;
}

export function getRefreshToken() {
  // console.log(localStorage.getItem("token"));
  return localStorage.getItem("refreshToken");
}

export function validateRole(role) {
  if ("token" in localStorage) {
    const decodedToken = jwt_decode(localStorage.getItem("token"));
    if (_.includes(decodedToken.role, role)) {
      return { status: true, message: "User_Validated" };
    } else {
      return { status: false, message: "Unauthorized_Access" };
    }
  }
}

export function validateToken() {
  if ("token" in localStorage) {
    const decodedToken = jwt_decode(localStorage.getItem("token"));
    const now = new Date();
    const nowInMilliSeconds = now.getTime();
    const tokenExpiry = decodedToken.exp * 1000;
    if (tokenExpiry < nowInMilliSeconds) {
      return { status: false, message: "Token_Expired" };
    } else {
      return { status: true, message: "Token_Valid" };
    }
  } else {
    return { status: false, message: "Token_Unavailable" };
  }
}

export function getUser() {
  // console.log(localStorage.getItem("token"));
  const user = localStorage.getItem("user");
  const obj = JSON.parse(user);
  return obj;
}

// export function getCompany() {
//   const user = localStorage.getItem("user");
//   const obj = JSON.parse(user);
//   return obj.companyId;
// }
