import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UpwardIcon, DownwardIcon } from "./svgIcon";
export const NavLink = (props) => {
  const { children, currentPath } = props;
  let { link } = props;
  let activeClass = "";
  if (!link) {
    link = "#";
  }
  if (currentPath === link) {
    activeClass = "side-nav__item--active";
  }
  return (
    <li className={`${activeClass}`}>
      <Link to={link}>{children}</Link>
    </li>
  );
};

export const NavLinkMultilevel = (props) => {
  const { children, value } = props;
  const [state, setState] = useState(false);
  const onClick = () => {
    setState(!state);
  };
  const activeClass = state ? "is-active" : "";
  const currentClass = state ? "open" : "close";
  const currentIcon = state ? <UpwardIcon /> : <DownwardIcon />;
  return (
    <li>
      <a
        className={`${activeClass} is-current-color`}
        href="#"
        onClick={onClick}
      >
        {value}
        <span className="icon pull-right ml-6">{currentIcon}</span>
      </a>
      <ul className={`menu-list ${currentClass}`}>{children}</ul>
    </li>
  );
};
