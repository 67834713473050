import React from "react";
import { DateField, SelectContainer } from "core/components/inputWithAccessControl";
import Joi from "joi";
import { useLoading } from "core/hooks/loadingHook";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { getAlertsForLoggedInCompanyByType } from "./alertService";
import { FormCard } from "core/cards/formCard";
import { formatArrayToOptions } from "core/utility/util";
import { addDefaultOption } from "core/utility/util";

export const AlertSearch = ({ onSearchResults }) => {
    const { loaderContainer, startLoading, stopLoading } = useLoading();
    const alertTypeOptionArray = ["Data", "Connection"];
    const alertLevelOptionArray = ["Error", "Warning"];
    const alertTypeOption = addDefaultOption(formatArrayToOptions(alertTypeOptionArray));
    const alertLevelOption = addDefaultOption(formatArrayToOptions(alertLevelOptionArray));

    const schema = Joi.object({
        from: Joi.date().label("From").required(),
        to: Joi.date().label("To").required(),
        alertType: Joi.string().trim().label("Alert Type").required(),
        alertLevel: Joi.string().trim().label("Alert Level").required(),
    });
    const submitCallback = async (event) => {
        const { from, to,alertType, alertLevel } = inputs;
        console.log(inputs);
        const fromUnix = moment(from).unix();
        const toUnix = moment(to).add(moment.duration("23:59:59")).unix();
        console.log(fromUnix);
        console.log(toUnix);
        try {
            startLoading();
           
            const { data: alerts } = await getAlertsForLoggedInCompanyByType(
                fromUnix,
                toUnix,
                alertType,
                alertLevel

            );
            onSearchResults(alerts);
            if (alerts.length > 0) {
                toast.success("Data fetched");
            } else {
                toast.warn("No data found");
            }

            stopLoading();
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status === 400) {
                toast.warn("No data found");
            } else {
                toast.error(
                    "Error fetching alerts data. Please refer console for detailed error"
                );
            }
            stopLoading();
        }
    };
    const defaultInputs = {};
    const {
        inputs,
        errors,
        handleInputChange,
        handleSubmit,
        resetInput,
        additionalValidation,
    } = useSubmitForm(schema, submitCallback, defaultInputs);

    const accessToEdit = true;


    return (
        <React.Fragment>
            <FormCard
                formName={"Search Alerts"}
                onSubmit={handleSubmit}
                onFormReset={resetInput}
                submitAccess={true}
                submitText={"Search"}
            >
                <div className="columns">
                    <div className="column">
                        <DateField
                            identifier="from"
                            labelName="From"
                            step={5}
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                        />
                    </div>
                    {/* /.column */}
                    <div className="column">
                        <DateField
                            identifier="to"
                            labelName="To"
                            step={5}
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                        />
                    </div>

                    <div className="column">
                        <SelectContainer
                            identifier="alertType"
                            labelName="Alert Type"
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                            options={alertTypeOption}
                        // defaultValue={15}
                        ></SelectContainer>
                    </div>
                    <div className="column">
                        <SelectContainer
                            identifier="alertLevel"
                            labelName="Alert Level"
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                            options={alertLevelOption}
                        // defaultValue={15}
                        ></SelectContainer>
                    </div>
                </div>
                {/* /.columns */}
            </FormCard>
            {loaderContainer}
        </React.Fragment>
    );
};
