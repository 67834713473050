import React, { Component } from "react";
import { LocationPin } from "core/components/svgIcon";

export const SiteDetails = (props) => {
  const { site = { companyName: "XYZ", address: "XYZ" } } = props;

  const { companyName, address } = site;
  return (
    <div className="card card-full-height min-height--1">
      <header className="card-header">
        <p className="card-header-title">
          <span className="util-icon--large">
            <LocationPin />
          </span>
          Site Details
        </p>
      </header>
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              <img
                src="https://bulma.io/images/placeholders/96x96.png"
                alt="Placeholder image"
              />
            </figure>
          </div>
          {/* /.media-left */}
          <div className="media-content">
            <p className="title is-6">{companyName}</p>
            <p className="subtitle is-6">{address}</p>
          </div>
          {/* /.media-content */}
        </div>
        {/* /.media */}
      </div>
      {/* /.card-content */}
    </div>
  );
};
