import React, { useState, useEffect } from "react";
import { getLiveReadingsBySite } from "./nodeService";
import { getLiveReadings } from "./nodeService";

export const useLiveReadingHook = (siteId) => {
  const [liveReadings, setLiveReadings] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (siteId == 0) {
          const { data: liveReadings } = await getLiveReadings();
          // console.log(liveReadings);
          setLiveReadings(liveReadings);
        } else {
          const { data: liveReadings } = await getLiveReadingsBySite(siteId);
          // console.log(liveReadings);
          setLiveReadings(liveReadings);
        }
      } catch (error) {
        console.log("Could not fetch live readings");
        console.log(error);
      }
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return [liveReadings];
};
