import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const colors = [
  "#008600",
  "#FF0000",
  "#0000FF",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
  "#ff0000",
];

export const DataQualityCard = ({ dataQuality = 100 }) => {
  const data = [
    { name: "Data Quality (in %)", value: dataQuality * 1 },
    { name: "Error (in %)", value: 100 - dataQuality },
  ];
  return (
    <div className="card card-full-height">
      <div className="availability-container">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie dataKey="value" data={data}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
