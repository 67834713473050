import React from "react";

import { UpdatePassword } from "features/user/updatePassword";
import { useMasterListState } from "features/masterList/masterListProvider";

export const UserProfile = (props) => {
  const masterList = useMasterListState();
  const user = masterList["user"];

  return (
    <div className="container">
      <div className="card mx-2 my-2">
        <header className="card-header">
          <span className="card-header-title">
            <h5 className="is-size-5">User Details</h5>
          </span>
        </header>
        <div className="card-content">
          <div className="content">
            <h6 className="is-size-6">User Name: {user.name}</h6>
            <h6 className="is-size-6">Email: {user.email}</h6>
            <h6 className="is-size-6">Contact : {user.contactNo}</h6>
          </div>
        </div>
        {/* /.card-content */}
      </div>
      {/* /.card */}
      <UpdatePassword {...props} />
    </div>
  );
};
