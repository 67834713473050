import React, { useEffect } from "react";
import { DatePicker } from "react-widgets";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import _ from "lodash";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useState } from "react";
import { ReactMultiEmail, isEmail } from 'react-multi-email';

const fetchRequiredHtml = (required) => {
  const requiredHtml = (
    <sup
      className="ml-1"
      style={{
        color: "#f00",
        position: "relative",
        top: "-0.5rem",
        fontSize: "120%",
      }}
    >
      *
    </sup>
  );
  if (required) {
    return requiredHtml;
  } else {
    return "";
  }
};

export const EditTextField = (props) => {
  const {
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    editAccess = false,
    disabled = false,
    type = "text",
    required = true,
  } = props;
  let inputStatusClass = "";
  let message = "";

  const onChange = (event) => {
    event.persist();
    handleInputChange(event.target.name, event.target.value);
  };

  let inputValue = "";
  if (inputs[identifier]) {
    inputValue = inputs[identifier];
  } else if (typeof inputs[identifier] === "number") {
    inputValue = `${inputs[identifier]}`;
  }

  if (errors && errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (errors && inputValue) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const isRequired = fetchRequiredHtml(required);

  const inputField = (
    <input
      className={`input ${inputStatusClass}`}
      type={type}
      placeholder={labelName}
      name={identifier}
      onChange={onChange}
      value={inputValue}
      disabled={disabled}
    />
  );

  const labelField = <p className="is-size-6">{inputValue || ""}</p>;

  return (
    <div className="field">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <div className="control">{editAccess ? inputField : labelField}</div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};
EditTextField.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
};

export const TextAreaField = (props) => {
  const {
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    editAccess = false,
    disabled = false,
    required = true,
    rows = 10,
  } = props;
  let inputStatusClass = "";
  let message = "";

  let inputValue = "";
  if (inputs[identifier]) {
    inputValue = inputs[identifier];
  } else if (typeof inputs[identifier] === "number") {
    inputValue = `${inputs[identifier]}`;
  }

  if (errors && errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (errors && inputValue) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const onChange = (event) => {
    // console.log("In Handle Input Change");
    // console.log(event);
    event.persist();
    handleInputChange(event.target.name, event.target.value);
  };

  const isRequired = fetchRequiredHtml(required);

  const inputField = (
    <textarea
      className={`textarea ${inputStatusClass}`}
      placeholder={labelName}
      name={identifier}
      onChange={onChange}
      value={inputValue}
      disabled={disabled}
      rows={rows}
    ></textarea>
  );

  const labelField = <p className="is-size-6">{inputValue || ""}</p>;

  return (
    <div className="field">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <div className="control">{editAccess ? inputField : labelField}</div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};

TextAreaField.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  rows: PropTypes.number,
};

export const DateTimeField = (props) => {
  const {
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    editAccess = false,
    disabled = false,
    min = new Date(1970, 0, 1),
    max = new Date(),
    step = 30,
    format = "YYYY-MM-DD HH:mm:ss",
    required = true,
  } = props;
  let inputStatusClass = "";
  let message = "";
  let inputValue = "";

  if (inputs[identifier]) {
    inputValue = inputs[identifier];
  } else if (typeof inputs[identifier] === "number") {
    inputValue = `${inputs[identifier]}`;
  } else {
    inputValue = null;
  }

  if (errors && errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (errors && inputValue) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }
  const onChange = (value) => {
    // console.log(value);
    handleInputChange(identifier, value);
  };
  const isRequired = fetchRequiredHtml(required);

  const inputField = (
    <DatePicker
      includeTime
      className={`${inputStatusClass}`}
      onChange={onChange}
      value={inputValue}
      name={identifier}
      disabled={disabled}
      format={format}
      max={max}
      min={min}
      valueEditFormat={{ dateStyle: "short", timeStyle: "medium" }}
      valueDisplayFormat={{ dateStyle: "short", timeStyle: "medium" }}
    />
  );

  const labelField = <p className="is-size-6">{inputValue || ""}</p>;

  return (
    <div className="field">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <div className="control">{editAccess ? inputField : labelField}</div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};

DateTimeField.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  step: PropTypes.number,
  format: PropTypes.string,
};

export const DateField = (props) => {
  const {
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    disabled = false,
    editAccess = false,
    format = "YYYY-MM-DD",
    min = new Date(1970, 0, 1),
    max = new Date(),
    step = 30,
    required = true,
  } = props;
  let inputStatusClass = "";
  let message = "";

  let inputValue = "";
  // console.log(inputs[identifier]);
  // console.log(typeof inputs[identifier]);
  let minDate = new Date(1970, 0, 1);
  if (min && typeof min === "string") {
    minDate = moment(min, format).toDate();
  } else if (min && typeof min === "object") {
    minDate = min;
  }

  let maxDate = new Date();
  // console.log(typeof max);
  if (max && typeof max === "string") {
    maxDate = moment(max, format).toDate();
  } else if (max && typeof max === "object") {
    maxDate = max;
  }

  if (inputs[identifier] && typeof inputs[identifier] === "string") {
    inputValue = moment(inputs[identifier], format).toDate();
  } else if (inputs[identifier]) {
    inputValue = inputs[identifier];
  } else {
    inputValue = null;
  }

  if (errors && errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (errors && inputValue) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }
  const onChange = (value) => {
    // console.log(value);
    // console.log(typeof value);
    const formattedDate = moment(value).format("YYYY-MM-DD");
    // console.log(formattedDate);
    // console.log(typeof formattedDate);
    handleInputChange(identifier, formattedDate);
  };

  const isRequired = fetchRequiredHtml(required);
  // console.log(isRequired);
  const inputField = (
    <DatePicker
      className={`${inputStatusClass}`}
      onChange={onChange}
      value={inputValue}
      name={identifier}
      disabled={disabled}
      format={format}
      max={maxDate}
      min={minDate}
      valueEditFormat={{ dateStyle: "short" }}
      valueDisplayFormat={{ dateStyle: "short" }}
    />
  );

  const labelField = (
    <p className="is-size-6">
      {moment(inputs[identifier], format).format(format) || ""}
    </p>
  );

  return (
    <div className="field">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <div className="control">{editAccess ? inputField : labelField}</div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};

DateField.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,

  min: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  max: PropTypes.instanceOf(Date),
  step: PropTypes.number,
  format: PropTypes.string,
};

export const TimeField = (props) => {
  const {
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    disabled = false,
    editAccess = false,
    format = "HH:mm:ss",
    min = new Date(1970, 0, 1),
    max = new Date(),
    step = 30,
    required = true,
  } = props;
  let inputStatusClass = "";
  let message = "";

  let inputValue = "";
  // console.log(inputs[identifier]);
  // console.log(typeof inputs[identifier]);
  let minDate = new Date(1970, 0, 1);
  if (min && typeof min === "string") {
    minDate = moment(min, format).toDate();
  } else if (min && typeof min === "object") {
    minDate = min;
  }

  let maxDate = new Date();
  // console.log(typeof max);
  if (max && typeof max === "string") {
    maxDate = moment(max, format).toDate();
  } else if (max && typeof max === "object") {
    maxDate = max;
  }

  if (inputs[identifier] && typeof inputs[identifier] === "string") {
    inputValue = moment(inputs[identifier], format).toDate();
  } else if (inputs[identifier]) {
    inputValue = inputs[identifier];
  } else {
    inputValue = null;
  }

  if (errors && errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (errors && inputValue) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }
  const onChange = (value) => {
    // console.log(value);
    // console.log(typeof value);
    const formattedDate = moment(value).format("HH:mm:ss");
    // console.log(formattedDate);
    // console.log(typeof formattedDate);
    handleInputChange(identifier, formattedDate);
  };

  const isRequired = fetchRequiredHtml(required);
  // console.log(isRequired);
  const inputField = (
    <DatePicker
      includeTime={true}
      className={`input ${inputStatusClass}`}
      onChange={onChange}
      value={inputValue}
      name={identifier}
      disabled={disabled}
      disableCalendar={true}
      format={format}
      time={false}
      max={maxDate}
      min={minDate}
      step={step}
    />
  );

  const labelField = (
    <p className="is-size-6">
      {moment(inputs[identifier], format).format(format) || ""}
    </p>
  );

  return (
    <div className="field">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <div className="control">{editAccess ? inputField : labelField}</div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};

TimeField.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  max: PropTypes.instanceOf(Date),
  step: PropTypes.number,
  format: PropTypes.string,
};

export const MultiSelectContainer = (props) => {
  const {
    options,
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    editAccess = false,
    defaultValue = [],
    required = true,
  } = props;

  let inputStatusClass = "";
  let message = "";

  if (errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (inputs[identifier]) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const onChange = (event) => {
    handleInputChange(identifier, _.map(event, "value"));
  };

  const isRequired = fetchRequiredHtml(required);

  let formattedInput = defaultValue;
  if (inputs[identifier] && inputs[identifier].length > 0) {
    formattedInput = _.filter(options, function (o) {
      return _.includes(inputs[identifier], o.value);
    });
  }

  const inputField = (
    <div className={`select select-full-width ${inputStatusClass}`}>
      <MultiSelect
        options={options}
        value={formattedInput}
        onChange={onChange}
        labelledBy={identifier}
      />
    </div>
  );
  let labelValue = "";
  if (_.isArray(inputs[identifier])) {
    labelValue = inputs[identifier].join(",");
  }
  const labelField = <p className="is-size-6">{labelValue}</p>;

  return (
    <div className="field">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <div className="control">{editAccess ? inputField : labelField}</div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};

MultiSelectContainer.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  required: PropTypes.bool,
  default: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
};

export const SelectContainer = (props) => {
  const {
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    options,
    defaultValue = "",
    required = true,
    editAccess = false,
  } = props;
  let inputStatusClass = "";
  let message = "";

  if (errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (inputs[identifier]) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const onChange = (event) => {
    // console.log("In Handle Input Change");
    // console.log(event);
    event.persist();
    handleInputChange(event.target.name, event.target.value);
  };

  const isRequired = fetchRequiredHtml(required);

  const optionsHtml = [];
  _.forEach(options, function (option) {
    const { label, value, disabled = false } = option;

    optionsHtml.push(
      <option value={value} disabled={disabled} key={value}>
        {label}
      </option>
    );
  });

  const inputField = (
    <div className={`select select-full-width ${inputStatusClass}`}>
      <select
        name={identifier}
        value={inputs[identifier] || defaultValue}
        onChange={onChange}
      >
        {optionsHtml}
      </select>
    </div>
  );

  const labelField = <p className="is-size-6">{inputs[identifier] || ""}</p>;

  return (
    <div className="field">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <div className="control">{editAccess ? inputField : labelField}</div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};

SelectContainer.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.object),
};

export const CheckBox = (props) => {
  const {
    identifier,
    labelName,
    value,
    handleInputChange,
    inputs,
    errors,
    options,
    defaultValue = "",
    required = true,
    editAccess = false,
  } = props;
  let inputStatusClass = "";
  let message = "";

  if (errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (inputs[identifier]) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const isRequired = fetchRequiredHtml(required);

  const onChange = (event) => {
    // console.log("In Handle Input Change");
    // console.log(event);
    event.persist();
    handleInputChange(event.target.name, event.target.checked);
  };

  const currentState = inputs[identifier] ? inputs[identifier] : false;

  const inputField = (
    <input
      type="checkbox"
      name={identifier}
      onChange={onChange}
      checked={currentState}
    />
  );

  const labelField = (
    <input
      type="checkbox"
      name={identifier}
      disabled={true}
      checked={currentState}
    />
  );

  return (
    <div className="field">
      <label className="label">&nbsp;</label>
      <div className="control">
        <label className="label checkbox">
          {editAccess ? inputField : labelField}
          <span className="mx-2">
            {labelName}
            {isRequired}
          </span>
        </label>
      </div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};

export const MultiCheckboxContainer = (props) => {
  const {
    options,
    wrapper = "div",
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    editAccess = false,
    defaultValue = [],
    required = true,
  } = props;
  const Wrapper = wrapper;
  let inputStatusClass = "";
  let message = "";

  if (errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (inputs[identifier]) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const onChange = (event) => {
    // console.log("In Handle Input Change");
    // console.log(event);
    event.persist();
    let newValues = inputs[identifier] ? inputs[identifier] : [];
    // console.log(event.target);
    // console.log(event.target.value);
    // console.log(event.target.name);
    if (event.target.checked) {
      newValues.push(event.target.value);
    } else {
      newValues = _.filter(inputs[identifier], function (option) {
        return !(option === event.target.value);
      });
    }
    handleInputChange(identifier, newValues);
  };

  const isRequired = fetchRequiredHtml(required);

  const inputOptions = [];
  // console.log(inputs[identifier]);
  _.forEach(options, function (option) {
    const { label, value } = option;
    // console.log(
    //   `${inputs[identifier]} ${value} ${_.includes(inputs[identifier], value)}`
    // );
    const currentState = _.includes(inputs[identifier], value);
    // console.log(currentState);
    const inputField = (
      <input
        type="checkbox"
        name={label}
        value={value}
        onChange={onChange}
        checked={currentState}
      />
    );

    const labelField = (
      <input
        type="checkbox"
        name={label}
        value={value}
        disabled={true}
        checked={currentState}
      />
    );

    const element = (
      <Wrapper key={value}>
        <div className="field">
          <div className="control">
            {editAccess ? inputField : labelField}
            <span className="mx-2">{label}</span>
          </div>
          <p className={`help ${inputStatusClass}`}>{message}</p>
        </div>
      </Wrapper>
    );
    inputOptions.push(element);
  });

  return inputOptions;
};

MultiCheckboxContainer.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  required: PropTypes.bool,
  default: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
};

export const FileUpload = (props) => {
  const {
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    disabled = false,
    editAccess = false,
    required = true,
  } = props;

  let inputStatusClass = "";
  let message = "";

  const onChange = (event) => {
    event.persist();
    handleInputChange(event.target.name, event.target.files[0]);
  };

  const fileName = inputs[identifier] ? inputs[identifier].name : "";

  if (errors && errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (errors && fileName) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const isRequired = fetchRequiredHtml(required);

  const inputField = (
    <div className="file has-name is-right is-fullwidth">
      <label className="file-label">
        <input
          className={`input ${inputStatusClass} file-input`}
          type="file"
          name={identifier}
          placeholder={labelName}
          onChange={onChange}
        />
        <span className="file-cta">
          <span className="file-label">Choose a file…</span>
        </span>
        <span className="file-name">{fileName}</span>
      </label>
    </div>
  );

  const labelField = <p className="is-size-6">{"Akshay"}</p>;

  return (
    <div className="field">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <div className="control">{editAccess ? inputField : labelField}</div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </div>
  );
};

export const EditTextWithSearchField = (props) => {
  const {
    identifier,
    labelName,
    handleInputChange,
    inputs,
    errors,
    editAccess = false,
    disabled = false,
    type = "text",
    required = true,
    options = [],
    handleSearch,
    minLength = 6,
  } = props;
  let inputStatusClass = "";
  let message = "";

  const onChange = (event) => {
    event.persist();
    handleInputChange(event.target.name, event.target.value);
  };

  let inputValue = "";
  if (inputs[identifier]) {
    inputValue = inputs[identifier];
  } else if (typeof inputs[identifier] === "number") {
    inputValue = `${inputs[identifier]}`;
  }

  if (errors && errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (errors && inputValue) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const isRequired = fetchRequiredHtml(required);

  const optionsList = [];

  _.forEach(options, function (value) {
    optionsList.push(
      <option key={value} value={value}>
        {value}
      </option>
    );
  });
  const [loadingClass, setLoadingClass] = useState("");
  let searchButtonStatus = true;
  if (_.size(inputValue) >= minLength) {
    searchButtonStatus = false;
  }

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.selectionStart = inputRef.current.value.length;
    inputRef.current.selectionEnd = inputRef.current.value.length;
    // inputRef.click();
    setLoadingClass("");
  }, [options]);

  const handleSearchClick = () => {
    if (!searchButtonStatus) {
      setLoadingClass("is-loading");
      handleSearch(inputValue);
    }
  };

  const inputRef = useRef(null);
  const inputField = (
    <React.Fragment>
      <div className={`control`}>
        <input
          className={`input ${inputStatusClass}`}
          type={type}
          placeholder={labelName}
          name={identifier}
          list={"text_editors"}
          onChange={onChange}
          value={inputValue}
          disabled={disabled}
          ref={inputRef}
        />
        <datalist id="text_editors">
          <select>{optionsList}</select>
        </datalist>
      </div>
      <div className="control">
        <a
          className={`button is-info ${loadingClass}`}
          onClick={handleSearchClick}
          disabled={searchButtonStatus}
        >
          Search
        </a>
      </div>
    </React.Fragment>
  );

  const labelField = (
    <div className="control">
      <p className="is-size-6">{inputValue || ""}</p>
    </div>
  );

  return (
    <React.Fragment>
      <label className="label mx-3">
        {labelName}
        {isRequired}
      </label>
      <div className="field has-addons input-with-search">
        {editAccess ? inputField : labelField}
      </div>
      <p className={`help ${inputStatusClass}`}>{message}</p>
    </React.Fragment>
  );
};
EditTextWithSearchField.propTypes = {
  identifier: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  inputs: PropTypes.object,
  errors: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  editAccess: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  handleSearch: PropTypes.func.isRequired,
};

export const MultiEmailInputField = ({ identifier, labelName, handleInputChange, inputs, errors, editAccess, required = true,
}) => {
  const [emails, setEmails] = useState(inputs[identifier] || []);

  const handleChange = (_emails) => {
    setEmails(_emails);
    handleInputChange(identifier, _emails);
  };

  // Update internal state when inputs prop changes
  useEffect(() => {
    setEmails(inputs[identifier] || []);
  }, [inputs[identifier]]);

  const isRequired = fetchRequiredHtml(required);

  let inputStatusClass = "";
  let message = "";

  if (errors[identifier]) {
    inputStatusClass = "is-danger";
    message = errors[identifier];
  } else if (inputs[identifier]) {
    inputStatusClass = "is-success";
    message = "Validated Successfully";
  }

  const styles = {
    display: 'flex',
    flexDirection: 'column', // Ensure flex direction is column
    // minHeight: "120px"
    // Other styles...
  };
  const customStyles = {
    fontSize: "13px",
    padding: "5px",

  };

  return (
    <div style={styles} className="control">
      <label className="label">
        {labelName}
        {isRequired}
      </label>
      <ReactMultiEmail
        placeholder={`Input ${labelName}`}
        style={{ flex: 1 }}
        emails={emails}
        className={`input ${inputStatusClass}`}
        onChange={handleChange}
        autoResize={true}
        getLabel={(email, index, removeEmail) => (
          <div style={customStyles} data-tag key={index}>
            {email}
            {editAccess && (
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            )}

          </div>
        )}
      />
      <p className={`help ${inputStatusClass}`}>{message}</p>

    </div>
  );
};