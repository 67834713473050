export const stepsDashboard = [
  {
    target: ".user_details_card2",
    title: "This is License Details Card",
    content: (
      <div>
        <p>1. Red Indicates card expires</p>
        <p>2. Green indicates normal</p>
        <p>3. Yellow indicates card expired within month</p>
      </div>
    ),
  },
  {
    target: ".leaflet-map",
    content: "This Indicates Site Map Section",
  },
  {
    target: ".user_details_card",
    content: "This Indicates Company's Site and User Details Card",
  },
  {
    target: ".node-card-v2",
    content: "This is node Details Card",
  },
  {
    target: ".reports_icon",
    content: "This indicates reports & charts section",
  },
  {
    target: "#icon-enlarge",
    content: "This enlarges chart section",
  },
];
