import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useInterval } from "core/hooks/intervalHook";

export const CarouselV2 = ({
  slides = [],
  autoSlide = true,
  timerInterval = 6000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = _.size(slides);
  const changeCurrentSlide = (selectedIndex) => {
    setCurrentIndex(selectedIndex);
  };
  const onDirectionClick = (shiftIndex) => {
    // console.log(shiftIndex);
    let newIndex = currentIndex + shiftIndex;
    if (newIndex >= totalSlides) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = totalSlides - 1;
    }
    setCurrentIndex(newIndex);
  };

  useInterval(() => {
    // Your custom logic here
    onDirectionClick(1);
  }, timerInterval);

  const rows = [];
  for (let i = 0; i < totalSlides; i++) {
    const active = currentIndex == i ? "active" : "";
    rows.push(
      <span
        key={i}
        className={`carouselv2-dot ${active}`}
        onClick={() => changeCurrentSlide(i)}
      ></span>
    );
  }
  if (_.size(slides) > 1) {
    return (
      <React.Fragment>
        <div className="carouselv2-container p-2 ">
          {slides[currentIndex]}
          <a
            className="carouselv2-direction carouselv2-direction--prev"
            onClick={() => onDirectionClick(-1)}
          >
            ❮
          </a>
          <a
            className="carouselv2-direction carouselv2-direction--next"
            onClick={() => onDirectionClick(1)}
          >
            ❯
          </a>
        </div>
        <div className="carouselv2-dot-container">{rows}</div>
      </React.Fragment>
    );
  } else {
    return (
      <div className="carouselv2-container p-2 ">{slides[currentIndex]}</div>
    );
  }
};

export const CarouselV2Slide = ({ children, cssClasses = "" }) => {
  return <div className={`carouselv2-slide ${cssClasses}`}>{children}</div>;
};
