import React, { Component, useState } from "react";
import Joi from "joi";
import { toast } from "react-toastify";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { DateTimeField } from "core/components/inputWithAccessControl";
import { useLoading } from "core/hooks/loadingHook";
import moment from "moment";
import { getCetpTransmissionLog } from "./transmissionService";
import { Table } from "core/components/table";

export const CetpTransmission = () => {
  const { loaderContainer, startLoading, stopLoading } = useLoading();

  const [transmissionLogs, setTransmissionLogs] = useState([]);
  const [isTableHidden, setTableHidden] = useState([true]);

  const schema = Joi.object({
    from: Joi.date().label("From").required(),
    to: Joi.date().label("To").required(),
  });

  const submitRequest = async () => {
    // console.log("Submitted");
    if (!validateForm()) {
      return;
    }
    startLoading();
    const { from, to } = inputs;
    const fromUnix = moment(from, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").unix();
    const toUnix = moment(to, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").unix();

    const { data: transmissionLogs } = await getCetpTransmissionLog(
      fromUnix,
      toUnix
    );

    if (transmissionLogs.length > 0) {
      stopLoading();
      //   console.log(transmissionLogs);
      setTableHidden(false);
      setTransmissionLogs(transmissionLogs);
    } else {
      stopLoading();
      setTableHidden(true);
      toast.warn("No data found");
    }
  };

  const { inputs, errors, handleInputChange, handleSubmit, validateForm } =
    useSubmitForm(schema, submitRequest);

  const tableStyle = { height: "400px", width: "100%" };

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  const columnDefs = [
    {
      headerName: "Transmission Logs",
      children: [
        {
          headerName: "Job Name",
          field: "jobName",
        },
        {
          headerName: "Data timestamp",
          valueGetter: function (params) {
            const newDate = new Date(params.data.datetime * 1000);
            return newDate.toLocaleString();
          },
          minWidth: 200,
        },
        {
          headerName: "Batch No.",
          field: "processingBatch",
        },
        {
          headerName: "Status",
          valueGetter: function (params) {
            if (params.data.status === 1) {
              return "Success";
            } else {
              return "Failed";
            }
          },
        },
        {
          headerName: "Reason",
          field: "message",
        },
      ],
    },
  ];

  return (
    <section className="section is-relative">
      <div>
        <div className="is-size-4">CETP Transmission</div>
        <form onSubmit={handleSubmit}>
          <div className="columns is-multiline">
            <div className="column is-half">
              <DateTimeField
                identifier="from"
                labelName="From"
                step={5}
                handleInputChange={handleInputChange}
                inputs={inputs}
                errors={errors}
              />
            </div>
            <div className="column is-half">
              <DateTimeField
                identifier="to"
                labelName="To"
                step={5}
                handleInputChange={handleInputChange}
                inputs={inputs}
                errors={errors}
              />
            </div>
            <div className="column is-narrow">
              <button className="button is-primary" type="submit">
                Fetch Logs
              </button>
            </div>
          </div>
        </form>
      </div>

      {!isTableHidden && (
        <div className="mt-4">
          <Table
            tableStyle={tableStyle}
            rowData={transmissionLogs}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            paginationAutoPageSize={true}
            pagination={true}
          />
        </div>
      )}

      {loaderContainer}
    </section>
  );
};
