import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

export const StatsCard = (props) => {
  const {
    icon,
    title,
    value,
    footerItem,
    headerClassName,
    cardLink = "#",
  } = props;
  const redirectToTarget = () => {};
  return (
    <div className="stats-card">
      <Link className="stats-card__link" to={cardLink}>
        <StatsCardHeader className={headerClassName}>{icon}</StatsCardHeader>
        <StatsCardContent title={title} value={value} />
      </Link>
      <StatsCardFooter>{footerItem}</StatsCardFooter>
    </div>
  );
};

export const StatsCardHeader = (props) => {
  const { className } = props;
  return (
    <div className={`stats-card__header ${className}`}>{props.children}</div>
  );
};

export const StatsCardContent = (props) => {
  const { title, value } = props;
  return (
    <div className="stats-card__content">
      <span className="stats-card__title">{title}</span>
      <span className="stats-card__value">{value}</span>
    </div>
  );
};

export const StatsCardFooter = (props) => {
  return <div className="stats-card__footer">{props.children}</div>;
};

export const StatsCardFooterItem = (props) => {
  const { itemTitle, link = "#" } = props;
  return (
    <div className="stats-card__footer__item">
      <Link className="button is-primary is-small" to={link}>
        {itemTitle}
      </Link>
    </div>
  );
};
