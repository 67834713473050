import {
  BreadcrumbContainer,
  BreadcrumbItem,
} from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { useReportTemplateHook } from "features/advancedReport/reportTemplateHook.jsx";
import React from "react";
import { FeatureContainer } from "features/feature/featureContainer";

export const CompanyReport = () => {
  const { reportTemplateSelectElement, reportTemplateElements } =
    useReportTemplateHook();

  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Advanced Reports"}
          description={"reports designed specifically for you"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Advanced Reports"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <FeatureContainer
          requiredFeature="AdvanceReport"
          message="Advanced Report feature enables you to generate custom reports"
        >
          <div className="columns is-centered">
            <div className="column is-half">{reportTemplateSelectElement}</div>
            {/* /.column */}
          </div>
          {/* /.columns */}
          {reportTemplateElements}
        </FeatureContainer>
      </div>
    </React.Fragment>
  );
};
