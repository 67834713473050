import React from "react";
import { PageHeader } from "core/components/pageHeader";
import {
  BreadcrumbItem,
  BreadcrumbContainer,
} from "core/components/breadcrumb";
import { TabContainer } from "core/components/tab";
import { Panel } from "core/components/tab";
import { FeatureContainer } from "features/feature/featureContainer";
import { CetpTransmission } from "features/transmissionLogs/cetpTransmission";
import { SpcbTransmission } from "features/transmissionLogs/spcbTransmission";
import { CpcbTransmission } from "features/transmissionLogs/cpcbTransmission";
export const TransmissionDetails = () => {
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Transmission Log"}
          description={
            "shows status of the data transmission to required authorities"
          }
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Transmission Log"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="box m-2">
          <TabContainer selected={0}>
            <Panel title="CETP">
              <FeatureContainer
                requiredFeature="CETP_SYNC"
                message="CETP Transmission Logs can be viewed here."
              >
                <CetpTransmission />
              </FeatureContainer>
            </Panel>
            <Panel title="SPCB">
              <FeatureContainer
                requiredFeature="SPCB_SYNC"
                message="Transmission Logs of data sent to State PCB can be viewed
                    here."
              >
                <SpcbTransmission />
              </FeatureContainer>
            </Panel>
            <Panel title="CPCB">
              <FeatureContainer
                requiredFeature="CPCB_SYNC"
                message="Transmission Logs of data sent to Central PCB can be viewed here."
              >
                <CpcbTransmission />
              </FeatureContainer>
            </Panel>
          </TabContainer>
        </div>
      </div>
    </React.Fragment>
  );
};
