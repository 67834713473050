import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";

export const Table = (props) => {
  const {
    tableStyle,
    rowData,
    columnDefs,
    defaultColDef,
    paginationAutoPageSize = false,
    pagination = false,
    paginationPageSize = 6,
    frameworkComponents = {},
  } = props;

  const defaultTableStyle = { height: "20rem", width: "100%" };
  return (
    <React.Fragment>
      <div style={tableStyle || defaultTableStyle} className="ag-theme-alpine">
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={pagination}
          frameworkComponents={frameworkComponents}
          paginationPageSize={paginationPageSize}
        />
      </div>
    </React.Fragment>
  );
};
