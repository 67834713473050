import React, { useEffect } from "react";
import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";
// Carousel wrapper component
export const CarouselSlide = ({ children }) => {
  return <div className="item">{children}</div>;
};

export const Carousel = ({ children, options }) => {
  // Initialize all elements with carousel class.
  useEffect(() => {
    if (children) {
      if (options == null) {
        options = {
          autoplay: true,
          infinite: false,
          pagination: true,
          autoplaySpeed: 10000,
          pauseOnHover: false,
          loop:true,
          navigation:true,
         };
      }
      const carousels = bulmaCarousel.attach(".carousel",options, { } );
      // const carouselSlider = bulmaCarousel.attach(".items",options, { } );
     
   
    }
  }, []);
  
  return (
    <div id="carousel-demo" className="carousel carousel--hidden">
      {children}
    </div>
  );
};
