import React, { useState } from "react";

export const Panel = (props) => {
  return <div>{props.children}</div>;
};

export const TabContainer = (props) => {
  let { children, selected } = props;
  //   console.log(children);
  const [selectedTab, setSelectedTab] = useState(selected);
  const handleChange = (index) => {
    // console.log(index);
    setSelectedTab(index);
  };
  //   console.log(children[selectedTab]);

  return (
    <React.Fragment>
      <div className="tabs is-centered">
        <ul>
          {children.map((elem, index) => {
            let style = index === selectedTab ? "is-active" : "";
            return (
              <li
                className={style}
                key={index}
                onClick={() => handleChange(index)}
              >
                <a>{elem.props.title}</a>
              </li>
            );
          })}
        </ul>
      </div>
      <div>{children[selectedTab]}</div>
    </React.Fragment>
  );
};
