import React, { Component } from "react";
import { MenuIcon } from "core/components/svgIcon";
import { LogoIcon } from "core/components/svgIcon";

export const CpcbHeader = (props) => {
  const { toggleSidebar, onLogout } = props;
  return (
    <React.Fragment>
      <div className="header__logo logo">
        <div className="logo__icon">
          <LogoIcon></LogoIcon>
        </div>
        <h4 className="logo__text">Reconindia</h4>
      </div>
      <div className="header__burger" onClick={toggleSidebar}>
        <MenuIcon />
      </div>

      <div className="header__tray">
        <button className="button is-primary" onClick={onLogout}>
          Logout
        </button>
      </div>
    </React.Fragment>
  );
};
