import React, { useState } from "react";
import { AlertSearch } from "./alertSearch";
import { AlertsTable } from "./alertsTable";

export const AlertCrud = () => {
  const [alerts, setAlerts] = useState([]);
  const handleSearchResults = (output) => {
    setAlerts(output);
  };
  return (
    <React.Fragment>
      <AlertSearch onSearchResults={handleSearchResults} />
      <AlertsTable data={alerts} />
    </React.Fragment>
  );
};
