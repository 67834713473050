import Joi from "joi";
import _ from "lodash";
import { useState } from "react";
import { toast } from "react-toastify";

export const useSubmitForm = (schema, callback, defaultInputs = {}) => {
  const [inputs, setInputs] = useState(defaultInputs);
  const [errors, setErrors] = useState({});

  const updateErrors = (identifier, { error }) => {
    // console.log(result);
    let tempError = { ...errors };
    if (error) {
      const details = error.details;
      _.forEach(details, function ({ path, message }) {
        // const identifier = path[0];
        // console.log(message);
        tempError[identifier] = message;
      });
    } else {
      tempError[identifier] = null;
    }
    setErrors(tempError);

    // console.log(errors);
  };

  const updateAllErrors = ({ error }) => {
    // console.log(error);

    if (error) {
      let tempError = {};
      const details = error.details;
      _.forEach(details, function ({ path, message }) {
        const identifier = path[0];
        // console.log(message);
        tempError[identifier] = message;
      });
      setErrors(tempError);
    } else {
      setErrors({});
    }

    // console.log(errors);
  };

  const validateProperty = (name, value) => {
    // console.log(`${name} ${value}`);
    // console.log(schema.keys());
    const property = schema.extract(name);
    // console.log(property);
    // console.log(property["_refs"]);
    const propertySchema = { [name]: property };
    const input = { [name]: value };
    const references = property["_refs"]["refs"];
    if (references.length > 0) {
      _.forEach(references, function (ref) {
        const { root: referencedField } = ref;
        input[referencedField] = inputs[referencedField];
        propertySchema[referencedField] = Joi.optional();
        // console.log(ref);
        // console.log(referencedField);
        // console.log(ref);
      });
    }
    const propertySchemaObject = Joi.object(propertySchema);
    const result = propertySchemaObject.validate(input);
    updateErrors(name, result);
    // console.log(result);
  };

  // Returns true if form is valid
  const validateForm = (event) => {
    // console.log(inputs);
    const result = schema.validate(inputs, { abortEarly: false });

    updateAllErrors(result);
    if (result.error) {
      // console.log(result);
      return false;
    } else {
      return true;
    }
  };

  const additionalValidation = (name, additionalSchema, inputsArray) => {
    // console.log(inputs);
    const selectedInputs = _.pick(inputs, inputsArray);
    const result = additionalSchema.validate(selectedInputs, {
      abortEarly: false,
    });

    updateErrors(name, result);
    if (result.error) {
      // console.log(result);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (event) => {
    // console.log("In form submit");
    const isFormValid = validateForm();
    if (event) {
      event.preventDefault();
    }
    if (isFormValid) {
      // toast.info("Validated Successfully");
      callback();
    } else {
      toast.error("Validation Failed");
    }
  };

  const handleInputChange = (name, value) => {
    // console.log("In Handle Input Change");
    // console.log(event);
    // event.persist();
    validateProperty(name, value);
    setInputs((inputs) => ({
      ...inputs,
      [name]: value,
    }));
  };

  const resetInput = () => {
    setInputs({});
  };

  return {
    handleSubmit,
    handleInputChange,
    validateForm,
    resetInput,
    additionalValidation,
    inputs,
    errors,
  };
};
