import { FormCard } from 'core/cards/formCard'
import { Modal } from 'core/components/modal'
import React from 'react'
import { ParameterArrayForm } from './settings_parameterForm'

const SettingsModal = ({ open, onClose, parameterNames, nodeId }) => {
    return (
        <Modal open={open} onClose={onClose} width="full-width">
            <div className="card">

                <ParameterArrayForm parameterNames={parameterNames} nodeId={nodeId} onClose={onClose} />
            </div>
        </Modal>
    )
}

export default SettingsModal
