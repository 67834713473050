import config from "config.json";
import http from "core/service/httpService";

export function getCetpTransmissionLog(from, to) {
  return http.post(
    `${config.apiEndpoint}${config.msBackend}/transmission/cetp`,
    { from, to }
  );
}

export function getSpcbTransmissionLog(from, to) {
  return http.post(
    `${config.apiEndpoint}${config.msBackend}/transmission/spcb`,
    { from, to }
  );
}

export function getCpcbTransmissionLog(from, to) {
  return http.post(
    `${config.apiEndpoint}${config.msBackend}/transmission/cpcb`,
    { from, to }
  );
}
