import config from "config.json";
import http from "core/service/httpService";

export function updatePassword(password) {
  // console.log(temp);
  return http.put(`${config.apiEndpoint}/users/updatePassword`, {
    password: password,
  });
}

export function getLoggedInUserDetails() {
  return http.get(`${config.apiEndpoint}/users/me`);
}
