import { FormCard } from "core/cards/formCard";
import { masterReportSchema } from "./masterSchema";
import { useReportInputHook } from "./reportInputHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import _ from "lodash";
import { useMasterListState } from "features/masterList/masterListProvider";
import React from "react";
import { useEffect } from "react";
import { generateJasperReport } from "./advancedReportService";
import { downloadReport } from "core/utility/util";
import { toast } from "react-toastify";
import { useLoading } from "core/hooks/loadingHook";
import { fetchAssertOptions } from "core/utility/util";
const generateFieldsArray = (selectedTemplate) => {
  const {
    templateId,
    name,
    templateName,
    exportFormat,
    site,
    node,
    nodeTag,
    processTag,
    nodeType,
    reportType,
    datetime,
    timeInterval,
  } = selectedTemplate;
  const fields = ["reportName", "templateName", "templateId"];
  if (!_.isEmpty(site)) {
    fields.push("site");
  }

  if (!_.isEmpty(nodeType)) {
    fields.push("nodeType");
  }

  if (!_.isEmpty(nodeTag)) {
    fields.push("nodeTag");
  }

  if (!_.isEmpty(processTag)) {
    fields.push("processTag");
  }

  if (!_.isEmpty(node)) {
    fields.push("node");
  }

  if (!_.isEmpty(reportType)) {
    fields.push("reportType");
  }

  if (!_.isEmpty(datetime)) {
    fields.push("from");
    fields.push("to");
  }

  if (!_.isEmpty(exportFormat)) {
    fields.push("exportFormat");
  }

  if (!_.isEmpty(timeInterval)) {
    fields.push("interval");
  }
  return fields;
};

const checkIfKeyPresent = (inputObject, key, values) => {
  if (_.has(inputObject, key)) {
    if (_.isArray(inputObject[key])) {
      values[_.snakeCase(key)] = inputObject[key];
      values[`display_${_.snakeCase(key)}`] = _.join(inputObject[key], ", ");
    } else {
      values[_.snakeCase(key)] = [inputObject[key]];
      values[`display_${_.snakeCase(key)}`] = inputObject[key];
    }
  }
};

const checkIfSitePresent = (inputObject, key, values, masterList) => {
  if (_.has(inputObject, key)) {
    if (_.isArray(inputObject[key])) {
      values[key] = inputObject[key];
      const siteNameArray = _.map(inputObject[key], (siteId) => {
        const site = _.find(masterList, { id: siteId });
        const { name, companyName } = site;
        return `${name} - ${companyName}`;
      });
      values[`display_${key}`] = _.join(siteNameArray, ", ");
    } else {
      values[key] = [inputObject[key]];
      const site = _.find(masterList, { id: inputObject[key] });
      const { name, companyName } = site;
      values[`display_${key}`] = `${name} - ${companyName}`;
    }
  }
};

const checkIfNodePresent = (inputObject, key, values, masterList) => {
  if (_.has(inputObject, key)) {
    if (_.isArray(inputObject[key])) {
      values[key] = inputObject[key];
      const nodeNameArray = _.map(inputObject[key], (nodeId) => {
        const node = _.find(masterList, { id: _.parseInt(nodeId) });
        const { tagName, nodeType } = node;
        return `${tagName} - ${nodeType}`;
      });
      values[`display_${key}`] = _.join(nodeNameArray, ", ");
    } else {
      values[key] = [inputObject[key]];
      // console.log(inputObject[key]);
      // console.log(masterList);
      const node = _.find(masterList, { id: _.parseInt(inputObject[key]) });
      console.log(node);
      const { tagName, nodeType } = node;
      values[`display_${key}`] = `${tagName} - ${nodeType}`;
    }
  }
};

export const ReportTemplate = ({ selectedTemplate }) => {
  // Functions
  const addToFieldsIfPresent = (key, value, widthClass) => {
    if (!_.isEmpty(value)) {
      elements.push(
        fetchElement(key, widthClass, true, {
          value: value,
        })
      );
    }
  };

  // console.log(selectedTemplate);
  const { templateId, name, templateName } = selectedTemplate;
  const fields = generateFieldsArray(selectedTemplate);
  const { nodeTypes, nodeTags, sites, nodes: allNodes } = useMasterListState();
  const schema = _.pick(masterReportSchema, fields);
  // console.log(extendedSchema);
  const joiSchema = Joi.object(schema);
  const submitCallback = async (event) => {
    // console.log(inputs);
    const { templateName, reportName, templateId } = inputs;

    const values = {
      templateId: templateId,
      templateName: templateName,
      reportName: reportName,
    };
    if (_.has(inputs, "from")) {
      if (selectedTemplate["datetime"] == "DATE") {
        values["from"] = new Date(`${inputs["from"]} 00:00:00`);
        values["to"] = new Date(`${inputs["to"]} 23:59:59`);
      } else {
        values["from"] = inputs["from"];
        values["to"] = inputs["to"];
      }
    } else {
      values["from"] = new Date();
      values["to"] = new Date();
    }
    if (_.has(inputs, "exportFormat")) {
      values["exportFormat"] = inputs["exportFormat"];
    } else {
      values["exportFormat"] = "pdf";
    }
    const input = {};
    let assertOptions = {};
    if (_.has(inputs, "reportType")) {
      input[_.snakeCase("reportType")] = inputs["reportType"];
      const reportTypeCombination = selectedTemplate["reportType"];
      const reportTypeDict = {};
      _.forEach(reportTypeCombination, function (rt) {
        const tempArray = _.split(rt, "::");
        reportTypeDict[tempArray[0]] = tempArray[1];
      });
      assertOptions = fetchAssertOptions(reportTypeDict[inputs["reportType"]]);
    } else {
      input[_.snakeCase("reportType")] = "INTERVAL";
    }
    if (_.has(inputs, "interval")) {
      input[_.snakeCase("interval")] = _.toInteger(inputs["interval"]);
      const intervalCombination = selectedTemplate["timeInterval"];
      const intervalDict = {};
      _.forEach(intervalCombination, function (rt) {
        const tempArray = _.split(rt, "::");
        intervalDict[tempArray[1]] = tempArray[2];
      });
      assertOptions = fetchAssertOptions(intervalDict[inputs["interval"]]);
    }
    checkIfSitePresent(inputs, "site", input, sites);
    checkIfKeyPresent(inputs, "nodeType", input);
    checkIfKeyPresent(inputs, "nodeTag", input);
    checkIfKeyPresent(inputs, "processTag", input);
    if (!_.has(inputs, "node")) {
      // console.log(inputs);
      const filteredNodes = filterNodes(
        inputs["site"],
        inputs["nodeType"],
        inputs["nodeTag"],
        inputs["processTag"]
      );
      const filteredNodeIds = _.map(filteredNodes, "id");
      // console.log(inputs);
      const customInput = { node: filteredNodeIds };
      checkIfNodePresent(customInput, "node", input, allNodes);
    } else {
      checkIfNodePresent(inputs, "node", input, allNodes);
    }

    values["input"] = input;

    const additionalSchema = Joi.object({
      from: Joi.date().label("From Date").required(),
      to: Joi.date().label("To Date").required(),
    }).assert(assertOptions.condition, true, assertOptions.errorMessage);
    const inputsArray = ["from", "to"];

    const isValid = additionalValidation("to", additionalSchema, inputsArray);
    console.log(values);
    if (!isValid) {
      toast.error("Validation Error");
      return;
    }
    try {
      startLoading();
      const response = await generateJasperReport(values);
      let fileName = response.headers["x-blob-file"];
      downloadReport(fileName, response);
      stopLoading();
      toast.success("Report generated successfully.");
    } catch (error) {
      console.log(error);
      toast.error("Error while generating report");
      stopLoading();
    }
    // console.log(response);
  };
  const defaultInputs = {
    templateId: templateId,
    reportName: name,
    templateName: templateName,
  };
  const {
    inputs,
    errors,
    handleInputChange,
    handleSubmit,
    resetInput,
    additionalValidation,
  } = useSubmitForm(joiSchema, submitCallback, defaultInputs);

  useEffect(() => {
    resetInput();
    handleInputChange("reportName", name);
    handleInputChange("templateName", templateName);
    handleInputChange("templateId", templateId);
  }, [selectedTemplate]);

  //   const { reportTemplateElements } = useReportTemplateHook(options);
  const reportInputHookOptions = {
    handleInputChange,
    inputs,
    errors,
  };
  const { fetchElement, filterNodes } = useReportInputHook(
    reportInputHookOptions
  );
  let elements = [];
  if (selectedTemplate != undefined) {
    const {
      exportFormat,
      site,
      node,
      nodeTag,
      nodeType,
      processTag,
      reportType,
      datetime,
      timeInterval,
    } = selectedTemplate;
    addToFieldsIfPresent("site", site, "is-two-third");
    addToFieldsIfPresent("nodeTag", nodeTag, "is-one-third");
    addToFieldsIfPresent("nodeType", nodeType, "is-one-third");
    addToFieldsIfPresent("processTag", processTag, "is-one-third");
    addToFieldsIfPresent("node", node, "is-one-third");
    addToFieldsIfPresent("reportType", reportType, "is-one-third");
    addToFieldsIfPresent("from", datetime, "is-one-third");
    addToFieldsIfPresent("to", datetime, "is-one-third");
    addToFieldsIfPresent("interval", timeInterval, "is-one-third");
    addToFieldsIfPresent("exportFormat", exportFormat, "is-one-third");
  }

  // useEffect(() => {
  //   const {
  //     exportFormat,
  //     site,
  //     node,
  //     nodeTag,
  //     nodeType,
  //     reportType,
  //     datetime,
  //     timeInterval,
  //   } = selectedTemplate;
  //   console.log("Input is updated");
  //   addToFieldsIfPresent("site", site, "is-two-third");
  //   addToFieldsIfPresent("nodeTag", nodeTag, "is-one-third");
  //   addToFieldsIfPresent("nodeType", nodeType, "is-one-third");
  //   addToFieldsIfPresent("node", node, "is-one-third");
  //   addToFieldsIfPresent("reportType", reportType, "is-one-third");
  //   addToFieldsIfPresent("from", datetime, "is-one-third");
  //   addToFieldsIfPresent("to", datetime, "is-one-third");
  //   addToFieldsIfPresent("interval", timeInterval, "is-one-third");
  //   addToFieldsIfPresent("exportFormat", exportFormat, "is-one-third");
  // }, [inputs]);

  const accessToEdit = true;
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  return (
    <React.Fragment>
      <FormCard
        formName={"Advanced Report"}
        onSubmit={handleSubmit}
        onFormReset={resetInput}
        submitAccess={accessToEdit}
      >
        <div className="columns is-multiline">{elements}</div>
      </FormCard>
      {loaderContainer}
    </React.Fragment>
  );
};
