import config from "config.json";
import http from "core/service/httpService";

export function getAlerts(pastDays = 30) {
  return http.get(config.apiEndpoint + "/alerts/cpcb?days=" + pastDays);
}

export function getAlertsForCompany(companyId, pastDays = 30) {
  return http.get(
    `${config.apiEndpoint}${config.msBackend}/alerts/cpcb?days=${pastDays}&company=${companyId}`
  );
}

export function getAlertsForLoggedInCompany(from, to) {
  return http.post(
    `${config.apiEndpoint}${config.msBackend}/alerts/assignedNodes`,
    {
      from: from,
      to: to,
    }
  );
}

export function getAlertsForLoggedInCompanyByType(from, to, alertType, alertLevel) {
  return http.post(
    `${config.apiEndpoint}${config.msBackend}/alerts/assignedNodes/type`,
    {
      from: from,
      to: to,
      alertType: alertType,
      alertLevel: alertLevel,
    }
  );
}

export function getCpcbAlerts(from, to) {
  return http.post(`${config.apiEndpoint}${config.msCpcb}/cpcbAlerts`, {
    from: from,
    to: to,
  });
}

export function getCpcbAlertsForLoggedInCompany(from, to) {
  return http.post(
    `${config.apiEndpoint}${config.msCpcb}/cpcbAlerts/assigned`,
    {
      from: from,
      to: to,
    }
  );
}

export function getCpcbAlertsForSite(from, to, siteId) {
  return http.post(`${config.apiEndpoint}${config.msCpcb}/cpcbAlerts/site`, {
    from: from,
    to: to,
    siteId: siteId,
  });
}
