import React, { Component } from "react";
import { NavLink } from "core/components/navLink";
import {
  AlertsIcon,
  DashboardIcon,
  ProfileIcon,
  ReportsIcon2,
  TransIcon,
} from "core/components/svgIcon";

export const CompanySidebar = () => {
  return (
    <aside className="menu">
      <p className="menu-label">General</p>
      <ul className="menu-list">
        <NavLink link="/company/dashboard">
          <span className="menu__items">
            <DashboardIcon /> <span className="pl-2">Dashboard</span>
          </span>
        </NavLink>
        <NavLink link="/company/reports">
          <span className="menu__items">
            <TransIcon /> <span className="pl-2"> Reports</span>
          </span>
        </NavLink>
        <NavLink link="/company/transmission">
          <span className="menu__items">
            <ReportsIcon2 />
            <span className="pl-2">Transmission Log</span>
          </span>
        </NavLink>
        <NavLink link="/company/alerts">
          <span className="menu__items">
            <AlertsIcon /> <span className="pl-2">Alerts</span>
          </span>
        </NavLink>
        <NavLink link="/company/profile">
          <span className="menu__items">
            <ProfileIcon />
            <span className="pl-2"> Profile</span>
          </span>
        </NavLink>
      </ul>
    </aside>
  );
};
