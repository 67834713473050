import {
  BreadcrumbContainer,
  BreadcrumbItem,
} from "core/components/breadcrumb";
import { PageHeader } from "core/components/pageHeader";
import { Panel, TabContainer } from "core/components/tab";
import React, { useEffect, useState } from "react";

import { useMasterListState } from "features/masterList/masterListProvider";
import { NodeContainerTagWise } from "features/node/nodeContainerTagWise";
import { getNodesBySiteId } from "features/node/nodeService";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { DashboardSiteUserDetails } from "./dashboardSiteUserContainer";
import { CompanyLiveFeed } from "./liveFeed";

export const CpcbSite = (props) => {
  const { id: siteId } = useParams();
  // console.log(siteId);
  const { sites } = useMasterListState();
  const site = _.find(sites, { id: _.parseInt(siteId) });
  const [nodes, setNodes] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data: nodes } = await getNodesBySiteId(siteId);
      // console.log(nodes);
      setNodes(nodes);
    };
    fetchData();
  }, [site]);
  return (
    <React.Fragment>
      <div className="content-container__header">
        <PageHeader
          header={"Site details"}
          description={"shows site details"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} />
          <BreadcrumbItem label={"Sites"} active={true} />
        </BreadcrumbContainer>
      </div>
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="dashboard">
          <div className="dashboard__details">
            <DashboardSiteUserDetails site={site} />
          </div>
          {/* /.dashboard__details */}
          <div className="dashboard__tab-content">
            <div className="box m-2">
              <TabContainer selected={0}>
                <Panel title="Details">
                  <NodeContainerTagWise nodes={nodes} siteId={siteId} />
                </Panel>
                <Panel title="Live Feed">
                  <CompanyLiveFeed siteId={siteId} />
                </Panel>
              </TabContainer>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
