import Joi from "joi";

export const masterReportSchema = {
  templateId: Joi.number().label("Template Id").integer().positive().required(),
  reportName: Joi.string().label("Report Name").trim().required(),
  templateName: Joi.string().label("Template Name").trim().required(),
  exportFormat: Joi.string().label("Export Format").trim().required(),
  reportType: Joi.string().label("Report Type").trim().required(),
  from: Joi.date().label("From Date").required(),
  to: Joi.date().label("To Date").required(),
  interval: Joi.number().label("Interval").positive().integer().required(),
  nodeTag: [
    Joi.string().label("Node Tag").trim().required(),
    Joi.array().items(Joi.string().label("Node Tag").trim().required()),
  ],
  nodeType: [
    Joi.string().label("Node Type").trim().required(),
    Joi.array().items(Joi.string().label("Node Type").trim().required()),
  ],
  processTag: [
    Joi.string().label("Process Tag").trim().required(),
    Joi.array().items(Joi.string().label("Process Tag").trim().required()),
  ],
  site: [
    Joi.number().label("Site").positive().integer().required(),
    Joi.array().items(
      Joi.number().label("Site").positive().integer().required()
    ),
  ],
  node: [
    Joi.number().label("Node").integer().positive().required(),
    Joi.array().items(
      Joi.number().label("Node").integer().positive().required()
    ),
  ],
};
