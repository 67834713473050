import {
  DateField,
  DateTimeField,
  MultiSelectContainer,
  SelectContainer,
  TimeField,
} from "core/components/inputWithAccessControl";
import _ from "lodash";
import { useMasterListState } from "features/masterList/masterListProvider";
import { useState } from "react";
import { addDefaultOption, formatArrayToOptions } from "core/utility/util";

export const useReportInputHook = (options) => {
  const { handleInputChange, inputs, errors } = options;

  const {
    nodeTypes,
    nodeTags,
    sites,
    nodes: allNodes,
    processTags,
  } = useMasterListState();
  const [nodes, setNodes] = useState(allNodes);
  // NodeType

  const filterNodes = (site, nodeType, nodeTag, processTag) => {
    // console.log(`${site} ${nodeType} ${nodeTag}`);
    let filteredNodes = allNodes;
    let atleastOneSelected = false;
    if (!_.isEmpty(site)) {
      atleastOneSelected = true;
      const siteArray = _.isArray(site) ? site : [_.parseInt(site)];
      filteredNodes = _.filter(filteredNodes, (node) => {
        // console.log(node);
        // console.log(siteArray);
        return _.includes(siteArray, node.siteId);
      });
    }
    if (!_.isEmpty(nodeType)) {
      atleastOneSelected = true;
      const nodeTypeArray = _.isArray(nodeType) ? nodeType : [nodeType];
      filteredNodes = _.filter(filteredNodes, (node) => {
        return _.includes(nodeTypeArray, node.nodeType);
      });
    }
    if (!_.isEmpty(nodeTag)) {
      atleastOneSelected = true;
      const nodeTagArray = _.isArray(nodeTag) ? nodeTag : [nodeTag];
      filteredNodes = _.filter(filteredNodes, (node) => {
        return _.includes(nodeTagArray, node.tagName);
      });
    }
    if (!_.isEmpty(processTag)) {
      atleastOneSelected = true;
      const processTagArray = _.isArray(processTag) ? processTag : [processTag];
      let processTagNodeTemp = [];
      _.forEach(processTagArray, (processTagInput) => {
        console.log(processTagInput);
        console.log(processTags);
        const matchedProcessTag = _.find(processTags, {
          tagName: processTagInput,
        });
        console.log(matchedProcessTag);
        if (matchedProcessTag != undefined) {
          processTagNodeTemp = _.concat(
            processTagNodeTemp,
            matchedProcessTag.nodes
          );
        }
      });
      console.log(processTagNodeTemp);
      const processTagNodeIdTemp = _.map(processTagNodeTemp, "id");
      const processTagNodeIdArray = _.uniq(processTagNodeIdTemp);
      console.log(processTagNodeIdArray);
      filteredNodes = _.filter(filteredNodes, (node) => {
        return _.includes(processTagNodeIdArray, node.id);
      });
    }

    return atleastOneSelected ? filteredNodes : [];
  };

  const nodeTypeElement = (editAccess, options) => {
    const { value } = options;
    // console.log(value);
    const handleNodeTypeChange = (name, value) => {
      const filteredNodes = filterNodes(
        inputs["site"],
        value,
        inputs["nodeTag"],
        inputs["processTag"]
      );
      setNodes(filteredNodes);
      handleInputChange(name, value);
    };
    if (_.toUpper(value) === "SINGLE") {
      const nodeTypeOptions = addDefaultOption(formatArrayToOptions(nodeTypes));
      return (
        <SelectContainer
          identifier="nodeType"
          labelName="Node Type"
          handleInputChange={handleNodeTypeChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={nodeTypeOptions}
        />
      );
    } else if (_.toUpper(value) === "MULTIPLE") {
      const nodeTypeOptions = formatArrayToOptions(nodeTypes);
      return (
        <MultiSelectContainer
          identifier="nodeType"
          labelName="Node Type"
          handleInputChange={handleNodeTypeChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={nodeTypeOptions}
        />
      );
    }
  };

  // NodeTag

  const nodeTagElement = (editAccess, options) => {
    const { value } = options;
    const handleNodeTagChange = (name, value) => {
      const filteredNodes = filterNodes(
        inputs["site"],
        inputs["nodeType"],
        value,
        inputs["processTag"]
      );
      setNodes(filteredNodes);
      handleInputChange(name, value);
    };
    if (_.toUpper(value) === "SINGLE") {
      const nodeTagOptions = addDefaultOption(formatArrayToOptions(nodeTags));
      return (
        <SelectContainer
          identifier="nodeTag"
          labelName="Node Tag"
          handleInputChange={handleNodeTagChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={nodeTagOptions}
        />
      );
    } else if (_.toUpper(value) === "MULTIPLE") {
      const nodeTagOptions = formatArrayToOptions(nodeTags);
      return (
        <MultiSelectContainer
          identifier="nodeTag"
          labelName="Node Tag"
          handleInputChange={handleNodeTagChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={nodeTagOptions}
        />
      );
    }
  };

  // ProcessTag

  const processTagElement = (editAccess, options) => {
    const { value } = options;
    const handleprocessTagChange = (name, value) => {
      const filteredNodes = filterNodes(
        inputs["site"],
        inputs["nodeType"],
        inputs["nodeTag"],
        value
      );
      setNodes(filteredNodes);
      handleInputChange(name, value);
    };
    if (_.toUpper(value) === "SINGLE") {
      const processTagOptions = addDefaultOption(
        formatArrayToOptions(_.map(processTags, "tagName"))
      );
      return (
        <SelectContainer
          identifier="processTag"
          labelName="Group Tag"
          handleInputChange={handleprocessTagChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={processTagOptions}
        />
      );
    } else if (_.toUpper(value) === "MULTIPLE") {
      const processTagOptions = formatArrayToOptions(processTags);
      return (
        <MultiSelectContainer
          identifier="processTag"
          labelName="Group Tag"
          handleInputChange={handleprocessTagChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={processTagOptions}
        />
      );
    }
  };

  // Node
  const nodeElement = (editAccess, options) => {
    const { value } = options;
    // console.log(value);
    const generateNodeOption = (node) => {
      // console.log(node);
      const { id, tagName, nodeType } = node;
      return { label: `${nodeType} - ${tagName}`, value: id };
    };

    if (_.toUpper(value) === "SINGLE") {
      const nodeOptions = addDefaultOption(_.map(nodes, generateNodeOption));
      return (
        <SelectContainer
          identifier="node"
          labelName="Node"
          handleInputChange={handleInputChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={nodeOptions}
        />
      );
    } else if (_.toUpper(value) === "MULTIPLE") {
      const nodeOptions = _.map(nodes, generateNodeOption);
      return (
        <MultiSelectContainer
          identifier="node"
          labelName="Node"
          handleInputChange={handleInputChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={nodeOptions}
        />
      );
    }
  };

  // ExportFormat
  const exportFormatElement = (editAccess, options) => {
    const { value } = options;
    const selectOptions = addDefaultOption(formatArrayToOptions(value));
    return (
      <SelectContainer
        identifier="exportFormat"
        labelName="Export Format"
        handleInputChange={handleInputChange}
        editAccess={editAccess}
        inputs={inputs}
        errors={errors}
        options={selectOptions}
      />
    );
  };

  // Report Type
  const reportTypeElement = (editAccess, options) => {
    const splitAndSelect = (str) => {
      return _.split(str, "::")[0];
    };
    const { value } = options;
    const actualValue = _.map(value, splitAndSelect);
    const selectOptions = addDefaultOption(formatArrayToOptions(actualValue));
    return (
      <SelectContainer
        identifier="reportType"
        labelName="Report Type"
        handleInputChange={handleInputChange}
        editAccess={editAccess}
        inputs={inputs}
        errors={errors}
        options={selectOptions}
      />
    );
  };

  // Interval
  const intervalElement = (editAccess, options) => {
    const generateIntervalOption = (str) => {
      const splitArray = _.split(str, "::");
      return { label: splitArray[0], value: splitArray[1] };
    };
    const { value } = options;
    // const actualValue = _.map(value, splitAndSelect);
    const selectOptions = addDefaultOption(
      _.map(value, generateIntervalOption)
    );
    return (
      <SelectContainer
        identifier="interval"
        labelName="Interval"
        handleInputChange={handleInputChange}
        editAccess={editAccess}
        inputs={inputs}
        errors={errors}
        options={selectOptions}
      />
    );
  };

  // Site
  const siteElement = (editAccess, options) => {
    const { value } = options;
    const generateSiteOption = (site) => {
      const { id, name, companyName } = site;
      return { label: `${name} - ${companyName}`, value: id };
    };
    const handleSiteChange = (name, value) => {
      const filteredNodes = filterNodes(
        value,
        inputs["nodeType"],
        inputs["nodeTag"]
      );
      setNodes(filteredNodes);
      handleInputChange(name, value);
    };

    if (_.toUpper(value) === "SINGLE") {
      const siteOptions = addDefaultOption(_.map(sites, generateSiteOption));
      return (
        <SelectContainer
          identifier="site"
          labelName="Site"
          handleInputChange={handleSiteChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={siteOptions}
        />
      );
    } else if (_.toUpper(value) === "MULTIPLE") {
      const siteOptions = _.map(sites, generateSiteOption);
      return (
        <MultiSelectContainer
          identifier="site"
          labelName="Site"
          handleInputChange={handleSiteChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          options={siteOptions}
        />
      );
    }
  };

  // From
  const fromElement = (editAccess, options) => {
    const { value } = options;
    if (_.toUpper(value) == "DATE") {
      return (
        <DateField
          identifier="from"
          labelName="From Date"
          handleInputChange={handleInputChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
        />
      );
    } else if (_.toUpper(value) == "TIME") {
      return (
        <TimeField
          identifier="from"
          labelName="From Time"
          handleInputChange={handleInputChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
        />
      );
    } else {
      return (
        <DateTimeField
          identifier="from"
          labelName="From Date"
          handleInputChange={handleInputChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
        />
      );
    }
  };

  // To
  const toElement = (editAccess, options) => {
    const { value } = options;
    if (_.toUpper(value) == "DATE") {
      return (
        <DateField
          identifier="to"
          labelName="To Date"
          handleInputChange={handleInputChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          min={inputs["from"]}
        />
      );
    } else if (_.toUpper(value) == "TIME") {
      return (
        <TimeField
          identifier="to"
          labelName="To Time"
          handleInputChange={handleInputChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          min={inputs["from"]}
        />
      );
    } else {
      return (
        <DateTimeField
          identifier="to"
          labelName="To Date"
          handleInputChange={handleInputChange}
          editAccess={editAccess}
          inputs={inputs}
          errors={errors}
          min={inputs["from"]}
        />
      );
    }
  };

  const fetchElement = (name, columnClass, editAccess, options) => {
    switch (name) {
      case "exportFormat":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {exportFormatElement(editAccess, options)}
          </div>
        );
      case "from":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {fromElement(editAccess, options)}
          </div>
        );
      case "to":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {toElement(editAccess, options)}
          </div>
        );
      case "nodeType":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {nodeTypeElement(editAccess, options)}
          </div>
        );
      case "nodeTag":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {nodeTagElement(editAccess, options)}
          </div>
        );
      case "processTag":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {processTagElement(editAccess, options)}
          </div>
        );

      case "reportType":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {reportTypeElement(editAccess, options)}
          </div>
        );
      case "interval":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {intervalElement(editAccess, options)}
          </div>
        );
      case "site":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {siteElement(editAccess, options)}
          </div>
        );
      case "node":
        return (
          <div key={name} className={`column ${columnClass}`}>
            {nodeElement(editAccess, options)}
          </div>
        );
    }
  };

  return {
    fetchElement,
    filterNodes,
  };
};
