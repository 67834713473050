import React from "react";
import { Link } from "react-router-dom";

export const BreadcrumbContainer = (props) => {
  const { children } = props;
  return (
    <nav
      className="breadcrumb is-small has-arrow-separator"
      aria-label="breadcrumbs"
    >
      <ul>{children}</ul>
    </nav>
  );
};

export const BreadcrumbItem = (props) => {
  const { label, link = "#", active = false } = props;
  let activeClass = "";
  if (active) {
    activeClass = "is-active";
  }
  return (
    <li className={activeClass}>
      <Link to={link} aria-current="page">
        {label}
      </Link>
    </li>
  );
};
