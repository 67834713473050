import { DropIcon2 } from "core/components/svgIcon__drop";
import { AirIcon2 } from "core/components/svgIcon__air";
import { DefaultIcon2 } from "core/components/svgIcon__default";
import { ThunderIcon2 } from "core/components/svgIcon__thunder";
import _ from "lodash";

export const NodeCardV2Avatar = ({ nodeType }) => {
  let avatarIcon = "";
  if (
    _.includes(
      [
        "FLOWMETER",
        "PHMETER",
        "WATER_QUALITY_ANALYZER",
        "TANK_LEVEL",
        "TDS_METER",
      ],
      _.toUpper(nodeType)
    )
  ) {
    avatarIcon = <DropIcon2 height={"6.5rem"} width={"6.5rem"} />;
  } else if (
    _.includes(
      [
        "AIR QUALITY SENSOR",
        "GAS_ANALYZER",
        "CO2_METER",
        "TANK_LEVEL",
        "VAPOUR COLUMN",
      ],
      _.toUpper(nodeType)
    )
  ) {
    avatarIcon = <AirIcon2 height={"6.5rem"} width={"6.5rem"} />;
  } else if (
    _.includes(
      [
        "POWERMETER",
        "POWER_FACTOR",
        "APPARENT_POWER",
        "VOLTAGE_P-P",
        "VOLTAGE_PHASE",
        "CURRENT",
        "POWER",
        "ENERGY_METER",
      ],
      _.toUpper(nodeType)
    )
  ) {
    avatarIcon = <ThunderIcon2 height={"6.5rem"} width={"6.5rem"} />;
  } else {
    avatarIcon = <DefaultIcon2 height={"6.5rem"} width={"6.5rem"} />;
  }
  return avatarIcon;
};
