import React, { Component, useState, useEffect } from "react";

import _ from "lodash";
import { NodeCard } from "./cards/nodeCard";
import { useLiveReadingHook } from "./liveReadingHook";
import { useMasterListState } from "features/masterList/masterListProvider";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { addDefaultOptionWithCustomName } from "core/utility/util";
import { FeatureContainer } from "features/feature/featureContainer";
import { HeartIcon } from "core/components/svgIcon";
import { NodeCardV2 } from "./cards/nodeCardV2";

export const NodeContainerTagWise = (props) => {
  const { nodes, siteId = 0 } = props;
  const { processTags } = useMasterListState();
  //   console.log(nodes);
  const [selectedNodes, setSelectedNodes] = useState(nodes);
  // console.log(siteId);
  const [liveReadings] = useLiveReadingHook(siteId);
  // console.log(liveReadings);
  // console.log(selectedNodes);
  // console.log(nodes);
  // Process Tags
  useEffect(() => {
    setSelectedNodes(nodes);
  }, [nodes]);
  const processTagOptions = addDefaultOptionWithCustomName(
    _.map(processTags, (pt) => {
      return { label: pt["tagName"], value: pt["id"] };
    }),
    "---  Show All  ---"
  );
  // const [selectedProcessTag, setSelectedProcessTag] = useState(undefined);
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  const handleProcessTagChange = (name, value) => {
    // console.log("In handle processtag change");
    // console.log(processTags);
    const temp = _.find(processTags, { id: _.toInteger(value) });
    // console.log(temp);
    // setSelectedProcessTag(temp);
    if (temp != undefined) {
      const nodesToDisplay = temp.nodes;
      const nodeIdsToDisplay = _.map(nodesToDisplay, "id");
      // console.log(nodeIdsToDisplay);
      const filteredNodeList = _.filter(nodes, (node) => {
        // console.log(node);
        return _.includes(nodeIdsToDisplay, node.id);
      });
      setSelectedNodes(filteredNodeList);
    } else {
      setSelectedNodes(nodes);
    }

    const newInput = { ...inputs };
    newInput[name] = value;
    setInputs(newInput);
  };

  // console.log(selectedNodes);
  // console.log(selectedProcessTag);
  return (
    <section className="section">
      <div className="container">
        <div className="legends legends--no-border">
          <div className="legends__item">
            <span className="fill-green ">
              <HeartIcon width="32" height="32" message={"ONLINE"} />
            </span>
            <p className="green_p is-size-6">
              Connected and responding properly
            </p>
          </div>
          <div className="legends__item">
            <span className="fill-yellow ">
              <HeartIcon width="32" height="32" message={"DELAYED"} />
            </span>
            <p className="yellow_p is-size-6">
              Connection lost for more than 15 minutes
            </p>
          </div>
          <div className="legends__item">
            <span className="fill-red ">
              <HeartIcon width="32" height="32" message={"OFFLINE"} />
            </span>
            <p className="red_p is-size-6">
              Connection lost for more than 4 hours
            </p>
          </div>
        </div>

        <FeatureContainer showHelp={false} requiredFeature="ProcessTag">
          <div className="columns is-centered">
            <div className="column is-half">
              <SelectContainer
                identifier="processTag"
                labelName="Select Group"
                handleInputChange={handleProcessTagChange}
                editAccess={true}
                inputs={inputs}
                errors={errors}
                options={processTagOptions}
              />
            </div>
          </div>
        </FeatureContainer>
        <div className="columns is-multiline">
          {selectedNodes.map((node, key) => {
            const liveReading = _.find(liveReadings, function (o) {
              return o.nodeId === node.id;
            });

            return (
              <div
                className="column is-half-tablet  is-one-third-desktop"
                key={key}
              >
                <NodeCardV2 node={node} liveReading={liveReading} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
