import React, { useEffect, useState } from "react";
import { useFeatureHook } from "features/feature/featureHook";
import _ from "lodash";
import { toast } from "react-toastify";
import { getCamerasBySite } from "./liveFeedService";

const { getAssignedCameras } = require("./liveFeedService");

export const useLiveFeedHook = (requiredFeature, siteId = 0) => {
  const [cameras, setCameras] = useState([]);
  const [selectedFeed, setSelectedFeed] = useState({});
  const [isAccessGranted] = useFeatureHook();
  const access = isAccessGranted(requiredFeature);

  const handleFeedChange = (event) => {
    const cameraId = event.target.value;
    const selectedCamera = _.find(cameras, { id: cameraId });
    setSelectedFeed(selectedCamera);
    // console.log(event.target.value);
  };
  let selectHtml = "";
  if (selectedFeed && selectedFeed.id && cameras.length > 0) {
    selectHtml = (
      <React.Fragment>
        <div className="field">
          <div className="control">
            <div className="select">
              <select
                defaultValue={selectedFeed.id}
                onChange={handleFeedChange}
              >
                {cameras.map((camera, key) => {
                  return (
                    <option value={camera.id} key={key}>
                      {camera.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <a
          className="button is-primary"
          href={selectedFeed.videoUrl}
          target="_blank"
        >
          Show Feed
        </a>
      </React.Fragment>
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (siteId == 0) {
          const { data: cameras } = await getAssignedCameras();
          if (cameras.length > 0) {
            setSelectedFeed(cameras[0]);
            setCameras(cameras);
          } else {
            setSelectedFeed({});
          }
        } else {
          const { data: cameras } = await getCamerasBySite(siteId);
          if (cameras.length > 0) {
            setSelectedFeed(cameras[0]);
            setCameras(cameras);
          } else {
            setSelectedFeed({});
          }
        }
      } catch (ex) {
        console.log(ex);
        toast.error("Error while fetching camera");
      }
    };
    if (access && !(selectedFeed && selectedFeed.id)) {
      fetchData();
    }
  }, [access]);

  let feedViewHtml = (
    <div className="live-feed__view__header is-size-4">No Feed Available</div>
  );
  if (selectedFeed && selectedFeed.id) {
    feedViewHtml = (
      <React.Fragment>
        <div className="live-feed__view__header is-size-4">
          {selectedFeed.name}
        </div>
        <iframe
          title="LiveFeedView"
          src={selectedFeed.videoUrl}
          className="live-feed__view__content"
        />
      </React.Fragment>
    );
  }

  return {
    selectHtml,
    feedViewHtml,
  };
};
