import React from 'react'
import { EditTextField } from 'core/components/inputWithAccessControl';
import { toast } from 'react-toastify';
import Joi from 'joi';
import { useLoading } from 'core/hooks/loadingHook';
import _ from "lodash";
import { useSubmitForm } from 'core/hooks/submitFormHook';
import { ChangePasswordIcon } from 'core/components/svgIcon__changePass';
import { updatePassword } from 'features/user/userService';
import { logout } from 'features/authentication/authService';
import { useNavigate } from 'react-router-dom';

export const ResetPasswordPage = () => {

  const { loaderContainer, startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();

  const currentPassword = localStorage.getItem('resetPasswordToken');
  console.log("current passwd:,", currentPassword);

  const submitCallback = async () => {
    console.log("in submit call back!")
    const { newPassword, confirmNewPassword } = inputs;
    try {
      startLoading();
      if (newPassword === confirmNewPassword) {
        const { data: response } = await updatePassword(newPassword);
        toast.success("Password updated successfully. Logging out user.");
        logout();
        localStorage.clear();
        navigate("/");
      } else {
        toast.error("Passwords do not match");
      }
    } catch (ex) {
      toast.error("Unable to update password. Please try again later!");
      console.log(ex);
    }
    stopLoading();
  };


  const schema = Joi.object({
    oldPassword: Joi.string()
      .label("Old Password")
      .messages({
        "string.pattern.base": `"Old Password" does not match`,
      })
      .trim()
      .required()

      .custom((value, helpers) => {
        // Custom validation function to compare oldPassword with currentPassword
        if (value !== currentPassword) {
          return helpers.error('any.invalid');
        }
        return value;
      }),
    newPassword: Joi.string()
      .trim()
      .pattern(new RegExp("^[a-zA-Z0-9_@#]{5,30}$"))
      .required()
      .label("New Password")
      .messages({
        "string.pattern.base": `"New Password" should be a alphanumeric combination with 5-30 characters`,
      }),
    confirmNewPassword: Joi.ref('newPassword')
  });
  const { inputs, errors, handleInputChange, handleSubmit } = useSubmitForm(
    schema,
    submitCallback

  );
  return (
    <>

      <section className="hero is-success is-fullheight svg-back login">
        <div className="hero-body ">
          <div className="container ">
            <div className="column is-4 is-offset-4 ">
              {/* <p className="subtitle has-text-black has-text-centered ">Reset Password</p> */}
              <div className="box box-shadow" style={{ backgroundColor: "#e8e8e8" }}>
                <figure className="avatar has-text-centered">
                  <ChangePasswordIcon />
                </figure>
                <p className=" has-text-centered "><span className="animate-charcter ">Reset  your Password</span></p>

                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <div className="">
                      <EditTextField
                        identifier="oldPassword"
                        labelName="Old Password"
                        handleInputChange={handleInputChange}
                        inputs={inputs}
                        errors={errors}
                        editAccess={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <EditTextField
                        identifier="newPassword"
                        labelName="New Password"
                        type="password"
                        handleInputChange={handleInputChange}
                        inputs={inputs}
                        errors={errors}
                        editAccess={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <EditTextField
                        identifier="confirmNewPassword"
                        labelName="Confirm New Password"
                        type="password"
                        handleInputChange={handleInputChange}
                        inputs={inputs}
                        errors={errors}
                        editAccess={true}
                      />
                    </div>
                  </div>

                  <button className="button is-block  reset_pass_btn  is-fullwidth" type='submit'>Confirm Password <i className="fa fa-sign-in" aria-hidden="true"></i></button>
                </form>
              </div>

            </div>
          </div>
        </div>
        {loaderContainer}
      </section>
    </>)
}
