import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";

export const TableWithGrid = (props) => {
  const {
    tableStyle,
    rowData,
    columnDefs,
    colDef,
    paginationAutoPageSize = false,
    pagination = false,
    paginationPageSize = 10,
    frameworkComponents = {},
    defaultFilter = {},
    theme = "balham",
  } = props;


  const onRowDataChanged = (event) => {
    const { api: gridApi } = event;
    gridApi.setFilterModel(defaultFilter);
    gridApi.onFilterChanged();
  };

  const defaultTableStyle = { height: "345px", width: "100%" };
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: false,
    flex: 1,
    minWidth: 150,
  };

  return (
    <React.Fragment>
      <div
        style={tableStyle || defaultTableStyle}
        className={`ag-theme-${theme} custom-table`}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={colDef || defaultColDef}
          pagination={pagination}
          frameworkComponents={frameworkComponents}
          onRowDataChanged={onRowDataChanged}
          paginationPageSize={paginationPageSize}
        />
      </div>
    </React.Fragment>
  );
};
