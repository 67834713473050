import React, { Component } from "react";
import red from "assets/img/video/red_small.mp4";
export const UserDetailsCard2 = (props) => {
  const { details: inputDetails } = props;
  let details = inputDetails;
  if (details == undefined) {
    details = {
      name: "John Doe",
      contactNumber: "0000000000",
      email: "user@example.com",
    };
  }
  const { name, contactNumber, email } = details;
  return (
    <div class="user_details_card">
      <video
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "50%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%,-50%)",
          zIndex: "-1",
        }}
        className="video__login"
      >
        <source src={red} type="video/mp4" />
      </video>
      <div class="user__details_section">
        <div class="card__text__section">
          <h3
            style={{ display: "flex" }}
            className="has-text-weight-bold	has-text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-user-round"
            >
              <circle cx="12" cy="8" r="5" />
              <path d="M20 21a8 8 0 0 0-16 0" />
            </svg>{" "}
            User Details
          </h3>
          <h2 className=" is-size-5 has-text-weight-bold has-text-white">
            {name}
          </h2>
          <h2 className=" is-size-6 has-text-white">{contactNumber}</h2>
        </div>
        <h4 className="is-size-6 has-text-white">{email}</h4>
      </div>
    </div>
  );
};
