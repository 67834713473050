import React, { useEffect, useState } from "react";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import { FormCard } from "core/cards/formCard";
import { EditTextField } from "core/components/inputWithAccessControl";
import _ from "lodash";
import { addDefaultOption } from "core/utility/util";
import { formatArrayToOptions } from "core/utility/util";
import { SelectContainer } from "core/components/inputWithAccessControl";
import { useMasterListState } from "features/masterList/masterListProvider";
import { toast } from "react-toastify";
import { useLoading } from "core/hooks/loadingHook";
import { saveExceedenceLimits } from "./nodeService";
import { node } from "prop-types";
import { getAssignAlerts } from "./nodeService";
import { TextAreaField } from "core/components/inputWithAccessControl";
import { MultiEmailInputField } from "core/components/inputWithAccessControl";

export const ParameterArrayForm = ({ selectedData, onClose, parameterNames, nodeId }) => {
  const [associatedAlerts, setAssociatedAlerts] = useState([]);

  const accessToEdit = true;

  const saveParameterData = async (result) => {
    try {
      startLoading();


      const body = { id: nodeId, exceedenceAlertLimitList: result };

      console.log("alert settings body :", body);

      const { data: parameter } = await saveExceedenceLimits(body);
      toast.success("Alert limits updated successfully");

      stopLoading();
    } catch (error) {
      console.log(error);
      toast.error("Error adding / editing alert details");
      stopLoading();
    }
    onClose();
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    console.log("submit parameter list", parameterList);
    const result = schema.validate(parameterList, { abortEarly: false });
    if (result.error) {
      console.log("settings result value", result);
      toast.error("Validation Error");
    } else {
      console.log(result);
      saveParameterData(result.value);
    }
  };

  const objectSchema = Joi.object({
    nodeId: Joi.number().label("Id").positive().integer().allow(0),
    parameterName: Joi.string().trim().label("Display Name").required(),
    // name: Joi.string().trim().label("Name").required(),
    alertLimit: Joi.number().label("Alert Limit").required(),
    limitType: Joi.string().trim().label("Limit Type").required(),
    alertEmails: Joi.array()
      .items(Joi.string().email({ tlds: { allow: ['com', 'net', 'in', 'org'] } }))
      .label("Alert Emails").min(1)
      .required(),
    status: Joi.string().trim().label("Status").required(),
    message: Joi.string().trim().label("Message").required(),

  });
  const schema = Joi.array().items(objectSchema).min(1).unique().required();
  const [parameterList, setParameterList] = useState([]);
  // useEffect(() => {
  //   if (parameters) {
  //     setParameterList(parameters);

  //   }
  // }, [parameters]);
  const handleAddRow = () => {
    const newList = [...parameterList];
    // newList.push({});
    newList.push({
      nodeId: nodeId, // Set the default id to nodeId
      parameterName: "", // You can set other default values if needed
      alertLimit: 0,
      limitType: "",
      alertEmails: "",
      status: "ENABLE",
      message: ""
    });
    setParameterList(newList);
  };
  const handleEditRow = (arrayIndex, name, value) => {
    // console.log(`Row edited ${arrayIndex} ${name} ${value}`);
    const updatedData = parameterList[arrayIndex];
    updatedData[name] = value;
    const updatedDataList = [...parameterList];
    updatedDataList[arrayIndex] = updatedData;
    setParameterList(updatedDataList);
  };
  const handleDeleteRow = async (arrayIndex) => {
    // console.log(`Delete row clicked ${arrayIndex}`);
    const newList = [...parameterList];
    newList.splice(arrayIndex, 1);
    // console.log(newList);
    setParameterList(newList);
    try {
      const parameterIdToDelete = associatedAlerts[arrayIndex].nodeId;
      console.log(parameterIdToDelete)

      const updatedList = [...parameterList];
      updatedList.splice(arrayIndex, 1);
      setParameterList(updatedList);

      const updatedAssociatedParameters = [...associatedAlerts];
      updatedAssociatedParameters.splice(arrayIndex, 1);
      setAssociatedAlerts(updatedAssociatedParameters);

      const body = { "id": JSON.stringify(parameterIdToDelete), parameters: updatedAssociatedParameters };

      // Call saveNodeParameters method to update the parameter list in the database
      // const { data: parameter } = await saveNodeParameters(id,body);
      // console.log(parameter);
      // toast.success("Parameter Deleted Successfully");
    } catch (error) {
      console.log(error);
      // toast.error("Error deleting parameter");
    }
  };

  // fetching assign alerts
  useEffect(() => {
    const fetchAssignAlerts = async () => {
      try {
        const { data: alerts } = await getAssignAlerts(nodeId)
        console.log(alerts)
        const parameterData = alerts || [];
        const updatedParameterList = parameterData.map(param => ({
          nodeId: param.nodeId,
          alertLimit: param.alertLimit,
          limitType: param.limitType,
          alertEmails: param.alertEmails || [],
          parameterName: param.parameterName,
          status: param.status,
          message: param.message
        }));
        setAssociatedAlerts(updatedParameterList);
        console.log("updated row list:", updatedParameterList);
        setParameterList(updatedParameterList);
        //  setParameterList(parameterData)     

      } catch (error) {
        console.log(error);
      }
    }
    if (nodeId != undefined) {
      fetchAssignAlerts();

    }

  }, [nodeId])

  useEffect(() => {
    if (!_.isEmpty(associatedAlerts)) {
      console.log(associatedAlerts);
      setParameterList(associatedAlerts);
    }
  }, [associatedAlerts]);

  const options = {
    buttons: (
      <button
        type="button"
        className="button is-info is-small mx-2"
        onClick={handleAddRow}
      >
        Add Parameter Alert
      </button>
    ),
  };
  let parameterRowList = [];
  _.forEach(parameterList, (p, index) => {
    // console.log(p);
    parameterRowList.push(
      <ParameterRow
        key={index}
        arrayIndex={index}
        schema={objectSchema}
        parameter={p}
        onRowEdit={handleEditRow}
        onRowDelete={handleDeleteRow}
        parameterName={parameterNames}
        nodeId={nodeId}
      />
    );
  });

  const { loaderContainer, startLoading, stopLoading } = useLoading();
  return (
    <React.Fragment>
      <FormCard
        formName={"Add/Update Alert"}
        onSubmit={handleSubmit}
        submitAccess={accessToEdit}
        options={options}
      >
        {parameterRowList}
      </FormCard>
      {loaderContainer}
    </React.Fragment>
  );
};

const ParameterRow = ({
  arrayIndex,
  schema,
  parameter,
  onRowEdit,
  onRowDelete,
  parameterName,
  nodeId
}) => {

  console.log("in parameter row names", parameterName)
  const parameterNameList = _.map(parameterName, "name");
  const parameterOptions = addDefaultOption(
    formatArrayToOptions(parameterNameList)
  );

  const limitType = [
    "Lower",
    "Upper",
  ];

  const statusType = [
    "ENABLE",
    "DISABLE",
  ]

  const limitTypeOptions = addDefaultOption(formatArrayToOptions(limitType));
  const statusOptions = addDefaultOption(formatArrayToOptions(statusType));
  const submitCallback = () => { };
  useEffect(() => {
    if (parameter) {
      const {

        nodeId,
        // name,
        parameterName,
        alertLimit,
        limitType,
        alertEmails,
        status,
        message
      } = parameter;
      handleInputChange("status", status);
      handleInputChange("parameterName", parameterName);
      handleInputChange("alertLimit", alertLimit);
      handleInputChange("limitType", limitType);
      handleInputChange("alertEmails", alertEmails);
      handleInputChange("nodeId", nodeId);
      handleInputChange("message", message);



    }
  }, [parameter]);

  const defaultInputs = {};


  const { inputs, errors, handleInputChange } = useSubmitForm(
    schema,
    submitCallback,
    defaultInputs
  );

  // Custom input change
  const handleCustomInputChange = (name, value) => {

    onRowEdit(arrayIndex, name, value);
    handleInputChange(name, value);

  };

  const [emails, setEmails] = useState([])
  const [focused, setFocused] = useState(false);

  return (
    <div className="box">
      <div className="columns is-multiline">
        <div className="column is-one-third">
          <EditTextField
            identifier="nodeId"
            labelName="Id"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={false}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <SelectContainer
            identifier="parameterName"
            labelName="Parameter Name"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            options={parameterOptions}
          />
        </div>
        {/* /.column */}

        <div className="column is-one-third">
          <EditTextField
            identifier="alertLimit"
            labelName="Alert Limit"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <SelectContainer
            identifier="limitType"
            labelName="Limit Type"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            options={limitTypeOptions}

          />
        </div>
        {/* /.column */}


        <div className="column is-one-third">
          <SelectContainer
            identifier="status"
            labelName="Status"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            options={statusOptions}

          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <TextAreaField
            identifier="message"
            labelName="Message"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
            rows={1}
          />
        </div>
        {/* /.column */}
        <div className="column is-one-third">
          <MultiEmailInputField
            identifier="alertEmails"
            labelName="Alert Email"
            handleInputChange={handleCustomInputChange}
            inputs={inputs}
            errors={errors}
            editAccess={true}
          />
        </div>
        {/* /.column */}

        <div className="column is-full">
          <button
            type="button"
            className="m-2 button is-small is-danger"
            onClick={() => onRowDelete(arrayIndex)}
          >
            Delete Row
          </button>
        </div>
        {/* /.column */}
      </div>
      {/* /.columns */}
    </div>
  );
};
