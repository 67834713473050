import React, { Component, useState, useEffect } from "react";
import { FeatureContainer } from "features/feature/featureContainer";
import { useFeatureHook } from "features/feature/featureHook";

import { useLiveFeedHook } from "features/liveFeed/liveFeedHook";

export const CompanyLiveFeed = () => {
  const requiredFeature = "LIVE_FEED";
  const { selectHtml, feedViewHtml } = useLiveFeedHook(requiredFeature);
  return (
    <section className="section">
      <div className="container">
        <FeatureContainer
          requiredFeature={requiredFeature}
          message="Live feed feature is disabled."
        >
          <div className="live-feed">
            <div className="live-feed__options">{selectHtml}</div>
            <div className="live-feed__view">{feedViewHtml}</div>
          </div>
        </FeatureContainer>
      </div>
    </section>
  );
};
