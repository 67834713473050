import {
  CrossIcon,
  EarthIcon,
  FactoryIcon,
  TimerIcon,
} from "core/components/svgIcon";

import { Modal } from "core/components/modal";
import { useLoading } from "core/hooks/loadingHook";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DataAvailabilityCard } from "./cards/dataAvailabilityCard";
import { DataQualityCard } from "./cards/dataQualityCard";
import { getDefaultReadings } from "./nodeService";
import ParameterCyclicChart from "./parameterCyclicChart";
import { ParametersCarouselCard } from "./cards/parametersCarouselCardV2";

export const NodeModal = ({ node, liveReading, open, onClose, site }) => {
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  // console.log(liveReading);
  // let site = {};

  const { name, companyName, address, category } = site;

  const { parameters, id: nodeId } = node;
  // console.log(node);
  const interval = 15;

  const [nodeInfo, setNodeInfo] = useState({});
  const [readingMap, setReadingMap] = useState({});
  useEffect(() => {
    const fetchData = () => {
      try {
        const { parameterValues, epochTime } = liveReading;
        let copy = { ...readingMap };

        _.forEach(parameterValues, function (value) {
          copy[value["identifier"]] = value["processedValue"];
        });

        setReadingMap({ ...copy });
        nodeInfo["dataFetchedAt"] = new Date(epochTime * 1000).toLocaleString();
        setNodeInfo(nodeInfo);
      } catch (ex) {
        toast.error("Error while fetching data");
      }
    };
    if (liveReading) {
      fetchData();
    }
  }, [liveReading]);

  const [rangeValue, setRangeValue] = useState(1);
  const [hideCharts, setHideCharts] = useState(true);
  const [readings, setReadings] = useState([]);
  const [dataAvailMap, setDataAvailMap] = useState(0);

  useEffect(() => {
    setHideCharts(true);
    startLoading();
    const fetchData = async () => {
      // console.log(rangeValue);
      const dateTo = moment();
      const dateFrom = moment().subtract(rangeValue, "d");
      const toUnix = moment(dateTo, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").unix();
      const fromUnix = moment(dateFrom, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'").unix();
      let values = [];
      try {
        const { data: inputValues } = await getDefaultReadings(
          nodeId,
          fromUnix,
          toUnix,
          interval
        );
        values = inputValues;
      } catch (error) {
        console.log(error);
      }
      if (values.length > 0) {
        stopLoading();
        setReadings(values);
        setRangeValue(rangeValue);
        // handleFormatReadings(values);
        let paramRows = values.length;
        let dataAvail = Number((paramRows / (96 * rangeValue)) * 100).toFixed(
          2
        );
        setDataAvailMap(dataAvail);
        setHideCharts(false);
      } else {
        stopLoading();
        toast.warn("No data found");
      }
    };
    fetchData();
  }, [rangeValue]);

  const handleRangeChange = async (event) => {
    const rangeValue = event.target.value;
    if (rangeValue) {
      setRangeValue(rangeValue);
    }
  };

  return (
    <Modal open={open} onClose={onClose} width="full-width">
      <div className="node-modal">
        <div className="card node-modal-card">
          <header className="card-header">
            <div className="card-header-title">
              <span className="is-size-4 has-text-centered">{companyName}</span>
            </div>

            <span
              className="svgicon is-24x24 is-padding-small"
              style={{ cursor: "pointer" }}
              onClick={onClose}
            >
              <CrossIcon />
            </span>
          </header>
          <div className="card-content node-modal-card-content">
            <div className="columns is-mobile is-multiline box">
              <div className="column is-narrow">
                <div className="field is-horizontal">
                  <div className="node-info__icon">
                    <span className="svgicon is-42x42 is-padding-medium">
                      <FactoryIcon />
                    </span>
                  </div>
                  <p className="node-info__name is-uppercase ">{name}</p>
                </div>
              </div>
              <div className="column is-narrow">
                <div className="field is-horizontal">
                  <div className="node-info__icon">
                    <span className="svgicon is-42x42 is-padding-medium">
                      <EarthIcon />
                    </span>
                  </div>
                  <p className="node-info__name is-uppercase">{address}</p>
                </div>
              </div>
              <div className="column is-narrow">
                <div className="field is-horizontal">
                  <div className="node-info__icon">
                    <span className="svgicon is-42x42 is-padding-medium">
                      <TimerIcon />
                    </span>
                  </div>
                  <p className="node-info__name is-uppercase">
                    Last data received at: {nodeInfo.dataFetchedAt}
                  </p>
                </div>
              </div>
              <div className="column is-narrow">
                <div className="field is-horizontal">
                  <div className="node-info__icon">
                    <span className="svgicon is-42x42 is-padding-medium">
                      <FactoryIcon />
                    </span>
                  </div>
                  <p className="node-info__name is-uppercase">{category}</p>
                </div>
              </div>
            </div>
            {/* /.header */}
            <div className="columns">
              <div className="column is-half-tablet is-one-third-desktop">
                <DataAvailabilityCard dataAvailability={dataAvailMap} />
              </div>
              <div className="column is-half-tablet is-one-third-desktop">
                <div className="node-modal__parameter-carousel box">
                  <ParametersCarouselCard
                    parameters={parameters}
                    readingMap={readingMap}
                  />
                </div>
              </div>
              <div className="column is-half-tablet is-one-third-desktop">
                <DataQualityCard dataQuality={100.0} />
              </div>
            </div>
            {/* /.carousel-row */}
            <div className="column">
              <div className="field ">
                <p className="label">Select Range </p>
                <div className="field-body buttons" onClick={handleRangeChange}>
                  <button className="button is-primary is-right" value={1}>
                    Daily
                  </button>
                  <button className="button is-primary is-right" value={7}>
                    Weekly
                  </button>
                  <button className="button is-primary is-right" value={30}>
                    Monthly
                  </button>
                </div>
              </div>
            </div>
            <div className="column is-full">
              {!hideCharts && (
                <div className="box">
                  <div className="columns is-centered">
                    <div className="column is-11">
                      <ParameterCyclicChart
                        parameters={parameters}
                        readings={readings}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* ./card-content */}
        </div>
        {/* /.card ./node-details-card */}
        {loaderContainer}
      </div>
    </Modal>
  );
};
