import React, { Component, useState, useEffect } from "react";
import {
  DropIcon,
  EnlargeIcon,
  HeartIcon,
  MenuIcon,
} from "core/components/svgIcon";
import { toast } from "react-toastify";
import _ from "lodash";
import { useModal } from "core/hooks/modalHook";
import { ReportChartModal } from "../reportChartModal";
import { NodeModal } from "../nodeModal";
import { CarouselV2, CarouselV2Slide } from "core/components/carouselv2";
import { NodeCardV2Avatar } from "./nodeCardV2__avatar";
import { ReportsIcon } from "core/components/svgIcon__report";
import { SettingsIcon } from "core/components/svgIcon";
import SettingsModal from "../settingsModal";
import { AlertIcon } from "core/components/svgIcon";
import { AlertsIcon } from "core/components/svgIcon";
import { AlertModal } from "../alertModal";
import { BellIcon } from "core/components/svgIcon__bell";
import { SettingIcon } from "core/components/svgIcon__setting";
// import { DiagnosticModal } from "../diagnosticModal";

const ParameterValue = ({ name, unit, value = 0 }) => {
  return (
    <div className="reading">
      <p className="reading__name is-text-primary is-size-6 is-uppercase">
        {name}
      </p>
      <p className="reading__value is-text-light is-size-6 has-text-centered">
        {value} <b>{unit}</b>
      </p>
    </div>
  );
};

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const date = today.getDate();
  return `${date}-${month}`;
}

export const NodeCardV2 = (props) => {
  // To be checked
  const [currentDate, setCurrentDate] = useState(getDate());
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000); // Update every second

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures the effect runs only once

  const hours = time.getHours();
  const minutes = time.getMinutes();

  // Format the time as we need (e.g., add leading zeros)
  const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

  // Ends Here

  const { node, liveReading } = props;
  const { parameters, diagnosticParameters, site, nodeType, tagName } = node;
  let paramLength = 3;
  const paramSize = _.size(parameters);
  if (paramSize >= 16) {
    paramLength = 8;
  }
  const [readingMap, setReadingMap] = useState({});
  useEffect(() => {
    const fetchData = () => {
      try {
        const { parameterValues, epochTime } = liveReading;
        let copy = { ...readingMap };
        _.forEach(parameterValues, function (value) {
          copy[value["identifier"]] = _.round(value["processedValue"], 3);
        });
        setReadingMap({ ...copy });
        setHeartBeatColour(epochTime);
      } catch (ex) {
        console.log(ex);
        toast.error("Error while fetching data");
      }
    };
    if (liveReading) {
      fetchData();
    }
  }, [liveReading]);

  const [heartbeatClass, setHeartbeatClass] = useState("fill-green");
  const [message, setMessage] = useState();
  const setHeartBeatColour = (epochTime) => {
    let fifteenMinutes = 15 * 60 * 1000;
    let fourHours = 4 * 60 * 60 * 1000;
    let datetime = Date.now() - epochTime * 1000;

    if (datetime < fifteenMinutes) {
      setHeartbeatClass("fill-green");
      setMessage("Online");
    } else if (fifteenMinutes <= datetime && datetime <= fourHours) {
      setHeartbeatClass("fill-yellow");
      setMessage("Delayed");
    } else if (datetime > fourHours) {
      setHeartbeatClass("fill-red");
      setMessage("Offline");
    }
  };

  const [nodeModal, openNodeModal, closeNodeModal] = useModal(false);
  const [settingsModal, openSettingsModal, closeSettingsModal] = useModal(false);
  const [alertModal, openAlertModal, closeAlertModal] = useModal(false);


  // const handleOpenNodeDetailsModal = () => {
  //   openNodeModal();
  // };

  // const [diagnosticModal, openDiagnosticModal, closeDiagnosticModal] =
  //   useModal(false);
  // const handleOpenDiagnosticModal = () => {
  //   if (diagnosticParameters && diagnosticParameters.length > 0) {
  //     openDiagnosticModal();
  //   } else {
  //     toast.warn("Diagnostic data not available for this node");
  //   }
  // };

  const [chart, openChart, closeChart] = useModal(false);
  const handleOpenChartModal = () => {
    openChart();
  };

  const slides = [];
  let columnList = [];
  _.each(parameters, function (parameter, i) {
    if (i % paramLength == 0 && i != 0) {
      let slideContainer = (
        <CarouselV2Slide cssClasses="px-2">
          <div className="columns is-gapless is-multiline">{columnList}</div>
        </CarouselV2Slide>
      );
      slides.push(slideContainer);
      columnList = [];
    }
    columnList.push(
      <div className="column is-full" key={i}>
        <ParameterValue
          name={parameter.name}
          unit={parameter.unit}
          value={readingMap[parameter.identifier]}
        />
      </div>
    );
  });
  if (_.size(columnList) > 0) {
    let slideContainer = (
      <CarouselV2Slide cssClasses="px-4">
        <div className="columns is-gapless is-multiline">{columnList}</div>
      </CarouselV2Slide>
    );
    slides.push(slideContainer);
  }

  const tagNameClass =
    _.size(tagName) < 20 && _.size(nodeType) < 20 ? "is-4" : "is-6";
  const nodeTypeClass =
    _.size(tagName) < 20 && _.size(nodeType) < 20 ? "is-5" : "is-6";

  return (
    <React.Fragment>
      <div className="node-card-v2 node-card-v2--short">
        <div className="node-card-v2__header is-red">
          {/* cards main icon section part */}

          <div className="node-card-v2__header__1 is-clearfix">
            {/* icons section of card started*/}
            <ul className="icons-section pull-right">
              <span className="is-pointer" onClick={openSettingsModal}>
                <SettingIcon height="2.5rem" width="3.1rem" />
              </span>
              <span className="is-pointer" onClick={openAlertModal}>
                <BellIcon height="2.5rem" width="3.1rem" />
              </span>
              <span className="is-pointer" onClick={handleOpenChartModal}>
                <ReportsIcon height="2.5rem" width="2.5rem" />
              </span>
              <span className={heartbeatClass}>
                <HeartIcon height="2.5rem" width="2.5rem" message={message} />
              </span>

              <span className="is-pointer" onClick={openNodeModal}>
                <EnlargeIcon height="2rem" width="2rem" />
              </span>
            </ul>
            {/* icons section of card ended*/}
          </div>
          {/* /.node-card-v2__header_1 */}
          <div className="node-card-v2__avatar">
            <div className="node-card-v2__avatar__image">
              <NodeCardV2Avatar nodeType={nodeType} />
            </div>
          </div>
          <div className="node-card-v2__header-content">
            <p className={`title ${tagNameClass}`}>{tagName}</p>
            <p className={`subtitle ${nodeTypeClass}`}>{nodeType}</p>
          </div>
        </div>
        {/* /.node-card-v2__header */}

        <div className="node-card-v2__content">
          <CarouselV2 slides={slides} />
        </div>
      </div>
      {/* /.node-card-v2 */}
      <div>
        <ReportChartModal open={chart} onClose={closeChart} node={node} />
      </div>
      {nodeModal ? (
        <div>
          <NodeModal
            open={nodeModal}
            onClose={closeNodeModal}
            node={node}
            liveReading={liveReading}
            site={site}
          />
        </div>
      ) : (
        ""
      )}
      {settingsModal ? (
        <div>
          <SettingsModal
            open={settingsModal}
            onClose={closeSettingsModal}
            parameterNames={parameters}
            nodeId={node.id}
          />
        </div>
      ) : (
        ""
      )}
      {alertModal ? (
        <div>
          <AlertModal
            open={alertModal}
            onClose={closeAlertModal}
            nodeId={node.id}
            tagName={tagName}
            nodeType={nodeType}

          />
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
