import { LoaderContainer } from "core/components/loader";
import React, { useState } from "react";

// https://medium.com/@geeky_writer_/using-react-hooks-to-create-awesome-forms-6f846a4ce57

export const useLoading = (initialPosition = "fixed") => {
  // initialPosition can be fixed or absolute
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Loading...");

  // const [position, setPosition] = useState(initialPosition);
  const loaderContainer = (
    <LoaderContainer
      message={message}
      loading={loading}
      position={initialPosition}
    />
  );
  const startLoading = (message = "Loading....") => {
    setLoading(true);
    setMessage(message);
  };

  const stopLoading = () => {
    setTimeout(() => {
      setLoading(false);
      setMessage("");
    }, 400);
    // setLoading(false);
    // setMessage("");
  };
  return {
    loaderContainer,
    startLoading,
    stopLoading,
  };
};
