import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { globalConfig, globalConfigUrl } from "./core/configuration/config";
import moment from "moment";
import MomentLocalizer from "react-widgets-moment";
import { Localization } from "react-widgets/cjs";
moment.locale("en");
const localizer = new MomentLocalizer(moment);

const root = ReactDOM.createRoot(document.getElementById("root"));

axios
  .get(globalConfigUrl)
  .then((response) => {
    const { data } = response;

    var offset = new Date().getTimezoneOffset();
    data["timezoneDifference"] = -1 * offset;
    globalConfig.set(data);
    console.debug("Global config fetched: ", data);
    return (
      <React.StrictMode>
        <Localization date={localizer}>
          <App />
        </Localization>
      </React.StrictMode>
    );
  })
  .catch((e) => {
    console.log(globalConfigUrl);
    return (
      <p style={{ color: "red", textAlign: "center" }}>
        Error while fetching global config
      </p>
    );
  })
  .then((reactElement) => {
    root.render(reactElement);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
