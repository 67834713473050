import { TableOne } from "core/components/tableOne";
import moment from "moment";
import _ from "lodash";
import React, { useState } from "react";

export const AlertsTable = ({ data }) => {
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    minWidth: 150,
  };

  const columnDefs = [
    {
      headerName: "Alerts",
      children: [
        {
          headerName: "TAG",
          field: "deviceTag",
        },
        {
          headerName: "PARAMETER",
          field: "parameter",
          minWidth: 150,
        },
        {
          headerName: "LEVEL",
          field: "alertLevel",
          minWidth: 150,
        },
        {
          headerName: "VALUE",
          valueGetter: function (params) {
            let unit = params.data.unit ? params.data.unit : "";
            // console.log(unit);
            unit = unit != "Minutes" ? unit : "";
            return `${params.data.value} ${unit}`;
          },
          minWidth: 250,
        },
        {
          headerName: "REFERENCE REASON",
          field: "reason",
          minWidth: 450,
        },
        {
          headerName: "GENERATED AT",
          valueGetter: function (params) {
            // const unit = moment(params.data.creationDate).unix();
            const timestamp = moment(params.data.creationDate).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            return `${timestamp}`;
          },
          minWidth: 200,
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <TableOne
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        paginationAutoPageSize={true}
        pagination={true}
      />
    </React.Fragment>
  );
};
