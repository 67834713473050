import React, { useState, useEffect } from "react";
import { getAssignedReportTemplates } from "./advancedReportService";
import { ReportTemplate } from "./advancedReportTemplate";
import dummy from "./dummyData.json";
import _ from "lodash";
import { addDefaultOption, formatArrayToOptions } from "core/utility/util";
import { SelectContainer } from "core/components/inputWithAccessControl";

export const useReportTemplateHook = () => {
  const [templateList, setTemplateList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: templateList } = await getAssignedReportTemplates();
        // console.log(templateList);
        // const templateList = dummy;
        setTemplateList(templateList);
      } catch (error) {}
    };
    fetchData();
  }, []);

  // Template names
  const templateNames = _.map(templateList, "name");
  const templateOptions = addDefaultOption(formatArrayToOptions(templateNames));
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const handleTemplateChange = (name, value) => {
    const temp = _.find(templateList, { name: value });
    setSelectedTemplate(temp);
    const newInput = { ...inputs };
    newInput[name] = value;
    setInputs(newInput);
  };

  const reportTemplateSelectElement = (
    <SelectContainer
      identifier="reportName"
      labelName="Report Template"
      handleInputChange={handleTemplateChange}
      editAccess={true}
      inputs={inputs}
      errors={errors}
      options={templateOptions}
    />
  );

  const [reportTemplateElements, setReportTemplateElements] = useState("");

  useEffect(() => {
    if (selectedTemplate) {
      setReportTemplateElements(
        <ReportTemplate selectedTemplate={selectedTemplate} />
      );
    } else {
      setReportTemplateElements("");
    }
  }, [selectedTemplate]);

  return {
    templateList,
    selectedTemplate,
    reportTemplateSelectElement,
    reportTemplateElements,
  };
};
