import config from "config.json";
import http from "core/service/httpService";

export function getAssignedCameras() {
  return http.get(`${config.apiEndpoint}${config.msBackend}/cameras/me`, {
    // headers: {
    //   "X-Forwarded-For": "192.168.0.156",
    // },
  });
}

export function getCamerasBySite(siteId) {
  return http.get(
    `${config.apiEndpoint}${config.msCpcb}/cameras/assign/${siteId}`,
    {
      // headers: {
      //   "X-Forwarded-For": "192.168.0.156",
      // },
    }
  );
}
