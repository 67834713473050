import React, { Component } from "react";
import { LocationPin } from "core/components/svgIcon";
import red from "assets/img/video/red_small.mp4";
import { IndustryIcon } from "core/components/svg__IconIndustry";
export const SiteDetails2 = (props) => {
  const { site = { companyName: "XYZ", address: "XYZ" } } = props;

  const { companyName, address } = site;
  return (
    <div class="user_details_card">
      <video
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "50%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%,-50%)",
          zIndex: "-1",
        }}
        className="video__login"
      >
        <source src={red} type="video/mp4" />
      </video>
      {/* avatar card section started */}
      <div className="avatar__section_card">
        <div className="avatar__section_image">
          <IndustryIcon />
        </div>
      </div>
      {/* avatar card section ended */}
      {/* company details section started     */}
      <div class="user__details_section has-text-white">
        <div class="card__text__section">
          <h3
            style={{ display: "flex" }}
            className="has-text-weight-bold has-text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-map-pin"
            >
              <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
              <circle cx="12" cy="10" r="3" />
            </svg>
            Site Details
          </h3>
          <h2 className=" is-size-5 has-text-weight-bold">{companyName}</h2>
        </div>
        <h3 class=" is-size-6">{address}</h3>
      </div>
      {/* company details section ended     */}
    </div>
  );
};
