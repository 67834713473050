import useWindowDimensions from "core/hooks/windowDimensionsHook";
import { logout } from "features/authentication/authService";
import { FeatureProvider } from "features/feature/featureProvider";
import { AuthenticationValidator } from "features/authentication/authenticationValidator";
import { MasterlistProvider } from "features/masterList/masterListProvider";
import React, { useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { CompanyHeader } from "./companyHeader";
import { CompanySidebar } from "./companySidebar";
import { CompanyReport } from "./companyReport";
import { Dashboard } from "./dashboard";
import { UserProfile } from "./profile";
import { TransmissionDetails } from "./transmissionLog";
import { AlertTable } from "./alertTable";

import roles from "roles.json";
import { AlertTableActions } from "./alertTableActions";
import { ResetPasswordPage } from "views/resetPasswordPage";

export const Company = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const { width, height } = useWindowDimensions();
  // const [sidebar, setSidebar] = useState(width > 1024);
  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };
  let sidebarClass = "";
  if (sidebar) {
    sidebarClass = "sidebar";
  } else {
    sidebarClass = "sidebar-hidden";
  }

  const options = { requiredRole: roles.COMPANY_ROLE };
  return (
    <AuthenticationValidator options={options}>
      <FeatureProvider>
        <MasterlistProvider>
          <React.Fragment>
            <div className="main">
              <header className="header">
                <CompanyHeader
                  toggleSidebar={toggleSidebar}
                  onLogout={handleLogout}
                />
              </header>
              <div className="content-area">
                <div className={`${sidebarClass}`}>
                  <CompanySidebar />
                </div>
                <div className="content-container">
                  <Routes>
                    <Route
                      path="transmission"
                      element={<TransmissionDetails />}
                    />

                    <Route path="profile" element={<UserProfile />} />
                    <Route path="alerts" element={<AlertTableActions />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="reports" element={<CompanyReport />} />
                    <Route path="resetPassword" element={<ResetPasswordPage />} />

                    <Route path="/" element={<Navigate to="dashboard" />} />
                  </Routes>
                </div>
              </div>
            </div>
          </React.Fragment>
        </MasterlistProvider>
      </FeatureProvider>
    </AuthenticationValidator>
  );
};
