import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { LocationPin } from "core/components/svgIcon";
import greenLocation from "assets/img/location-pin-green.svg";
import redLocation from "assets/img/location-pin-red.svg";
import yellowLocation from "assets/img/location-pin-yellow.svg";
import locationShadow from "leaflet/dist/images/marker-shadow.png";
import _ from "lodash";

const MarkerLegendItem = ({ color, text }) => {
  return (
    <span className="legend-item">
      <div className={`legend-item__icon fill-${color}`}>
        <LocationPin />
      </div>
      <span className="legend-item__text">{text}</span>
    </span>
  );
};

let mySvgString = ReactDOMServer.renderToString(
  <span className="util-icon--large fill-green">
    <LocationPin />
  </span>
);
let myIconUrl = encodeURI("data:image/svg+xml," + mySvgString).replace(
  "#",
  "%23"
);

const greenIcon = new L.Icon({
  iconUrl: greenLocation,
  iconRetinaUrl: greenLocation,
  //   iconUrl: myIconUrl,
  //   iconRetinaUrl: myIconUrl,

  shadowUrl: locationShadow,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  shadowAnchor: [14, 43],
});

const redIcon = new L.Icon({
  iconUrl: redLocation,
  iconRetinaUrl: redLocation,
  //   iconUrl: myIconUrl,
  //   iconRetinaUrl: myIconUrl,

  shadowUrl: locationShadow,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  shadowAnchor: [14, 43],
});

const yellowIcon = new L.Icon({
  iconUrl: yellowLocation,
  iconRetinaUrl: yellowLocation,
  //   iconUrl: myIconUrl,
  //   iconRetinaUrl: myIconUrl,

  shadowUrl: locationShadow,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
  shadowAnchor: [14, 43],
});

export const SiteMap = ({ siteList, centerPosition, zoom }) => {
  const markers = siteList.map((site, key) => {
    const position = [site.latitude || 0, site.longitude || 0];
    let selectedIcon = greenIcon;
    try {
      if (_.toUpper(site.connectionStatus) === "OFFLINE") {
        selectedIcon = redIcon;
      } else if (_.toUpper(site.connectionStatus) === "DELAY") {
        selectedIcon = yellowIcon;
      } else {
        selectedIcon = greenIcon;
      }
    } catch (error) {
      console.log(error);
      selectedIcon = greenIcon;
    }
    return (
      <Marker key={key} icon={selectedIcon} position={position}>
        <Popup>
          {/* <Link to={"/cpcb/companies/" + company.id}>{company.name}</Link> */}
          {`${site.name} ${site.companyName}`}
          <br /> {site.address}
        </Popup>
      </Marker>
    );
  });
  // console.log(markers);
  return (
    <div className="leaflet-map">
      <MapContainer
        center={centerPosition}
        zoom={zoom}
        style={{
          height: "100%",
          width: "100%",
          zIndex: 5,
        }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers}
      </MapContainer>
      <div className="leaflet-map__legend">
        <MarkerLegendItem color="green" text="Online" />
        <MarkerLegendItem color="red" text="Offline" />
        <MarkerLegendItem color="yellow" text="Delayed" />
      </div>
    </div>
  );
};
