import { Carousel, CarouselSlide } from "core/components/carousel";
import { SiteLicenseCard } from "./siteLicenseCard";
import _ from "lodash";
import { useMasterListState } from "features/masterList/masterListProvider";
import { useEffect, useState } from "react";
import { CarouselV2, CarouselV2Slide } from "core/components/carouselv2";
import { SiteLicenseCard2 } from "./siteLicenseCard2";

export const SiteLicenseCarouselCard = () => {
  const { licenses } = useMasterListState();

  const [carouselSlides, setCarouselSlides] = useState([]);

  useEffect(() => {
    const newSlides = [];
    _.forEach(licenses, (license) => {
      const { nodeName, expDate, nodeType } = license;
      newSlides.push(
        <CarouselV2Slide>
          <SiteLicenseCard2
            key={`${nodeName}-${expDate}`}
            tagName={`${nodeType}-${nodeName}`}
            expiryDate={expDate}
          />
        </CarouselV2Slide>
      );
    });
    setCarouselSlides(newSlides);
  }, [licenses]);
  // console.log(carouselSlides);
  let carousels = "";
  // console.log(carouselSlides);
  if (carouselSlides.length > 1) {
    return <CarouselV2 slides={carouselSlides}></CarouselV2>;
  } else if (carouselSlides.length == 1) {
    return carouselSlides[0];
  } else {
    return "";
  }
  return carousels;
};
