import { FormCard } from 'core/cards/formCard'
import { Modal } from 'core/components/modal'
import React from 'react'
import { ExceedenecAlertTable } from './exceedenceAlertTable'

export const AlertModal = ({ onClose, open, nodeId, tagName, nodeType }) => {
    return (
        <Modal open={open} onClose={onClose} width="full-width">
            <div className="card my-4 py-2 ">
                <div className="card-header">
                    <p className="card-header-title is-size-5">
                        <span className="tag is-danger is-light is-medium is-italic"> Exceedence Alerts for {tagName}-{nodeType} within 24 Hours</span>
                    </p>
                </div>

                <ExceedenecAlertTable nodeId={nodeId} />
            </div>
        </Modal>
    )
}
