import React from "react";
import { useFeatureHook } from "./featureHook";

export const FeatureContainer = ({
  children,
  message = "Feature is disabled",
  requiredFeature,
  showHelp = true,
}) => {
  const [isAccessGranted] = useFeatureHook();
  const featureAccess = isAccessGranted(requiredFeature)
    ? "enabled"
    : "disabled";

  const helpMessageHtml = showHelp ? (
    <div className="feature__help is-size-3 has-text-centered">
      {message}
      <br /> Contact your administrator if you wish to use this feature.
    </div>
  ) : (
    ""
  );

  return (
    <div className={`feature feature--${featureAccess}`}>
      <div className="feature__box">{children}</div>
      {helpMessageHtml}
    </div>
  );
};
