import _ from "lodash";
import Joi from "joi";

function isAccessGranted(permissions, requiredPermission) {
  return _.includes(permissions, requiredPermission);
}

function formatArrayToOptions(stringArray) {
  const optionArray = [];
  _.forEach(stringArray, function (value) {
    const option = { label: value, value: value };
    optionArray.push(option);
  });
  return optionArray;
}

function addDefaultOption(options) {
  const defaultOption = { label: "Select", value: "" };
  return [defaultOption, ...options];
}

function addDefaultOptionWithCustomName(options, customName) {
  const defaultOption = { label: customName, value: "" };
  return [defaultOption, ...options];
}

function downloadReport(fileName, response) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE variant
    window.navigator.msSaveOrOpenBlob(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      fileName
    );
  } else {
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/octet-stream",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }
}

function fetchAssertOptions(allowedInterval) {
  // console.log(allowedInterval);
  const timeInterval = allowedInterval.substring(allowedInterval.length - 1);
  const timeValue = allowedInterval.substring(0, allowedInterval.length - 1);
  // console.log(timeInterval);
  // console.log(timeValue);
  let assertOptions = {};
  if (_.toUpper(timeInterval) === "D") {
    assertOptions = {
      condition: Joi.expression(
        `{ (number(.to) - number(.from)) / day < ${timeValue} }`
      ),
      errorMessage: `Interval should not exceed ${timeValue} days`,
    };
  } else if (_.toUpper(timeInterval) === "M") {
    assertOptions = {
      condition: Joi.expression(
        `{ (number(.to) - number(.from)) / (31*day) < ${timeValue} }`
      ),
      errorMessage: `Interval should not exceed ${timeValue} months`,
    };
  } else if (_.toUpper(timeInterval) === "Y") {
    assertOptions = {
      condition: Joi.expression(
        `{ (number(.to) - number(.from)) / (366*day) < ${timeValue} }`
      ),
      errorMessage: `Interval should not exceed ${timeValue} years`,
    };
  }

  return assertOptions;
}

export {
  isAccessGranted,
  formatArrayToOptions,
  addDefaultOption,
  addDefaultOptionWithCustomName,
  downloadReport,
  fetchAssertOptions,
};
