import React, { useEffect } from "react";
import { logout } from "./authService";
import { updateAccessToken } from "./authService";
import { validateToken } from "./authService";
import { toast } from "react-toastify";
import { saveToken } from "./authService";
import { saveRefreshToken } from "./authService";
import { validateRole } from "./authService";

export const AuthenticationValidator = ({ options, children }) => {
  const { requiredRole } = options;
  useEffect(() => {
    const logoutAction = () => {
      toast.error("You are not logged in or unauthorized to access this page");
      logout();
      window.location = "/";
    };

    const authorizeUser = async () => {
      const token = validateToken();
      const { status: tokenStatus, message: tokenMessage } = token;
      if (!tokenStatus) {
        if (tokenMessage == "Token_Expired") {
          const response = await updateAccessToken();
          const { status, data } = response;
          if (status === 200 || status === 201 || status === 202) {
            saveToken(data.accessToken);
            saveRefreshToken(data.refreshToken);
            return;
          }
          logoutAction();
        } else if (tokenMessage == "Token_Unavailable") {
          logoutAction();
        }
      }
      const { status: roleStatus } = validateRole(requiredRole);
      if (!roleStatus) {
        logoutAction();
      }
    };

    authorizeUser();
    const interval = setInterval(() => {
      authorizeUser();
    }, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};
